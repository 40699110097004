// Images
import { noEarning } from "../../assets/images";

const NoEarning = () => {
    return <div className='my-5 text-center'>
        <img className='img-fluid mt-5 mb-3' src={noEarning} alt='No Earning' />
        <p className='fs-24 fw-600'>You Don’t have any Earnings at this moment.</p>
    </div>
};

export default NoEarning;