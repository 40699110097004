import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Linkify from "linkify-react";
import { CiLocationOn, CiCalendar } from "react-icons/ci";
import { FiEdit3 } from "react-icons/fi";
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from "classnames";

// Custom Component
import { PrimaryButton } from "../Button";
import SuccessAlert from "../Alert/SuccessAlert";
import ErrorAlert from "../Alert/ErrorAlert";
import ConfirmAlert from "../Alert/ConfirmAlert";

// Images
import { Badminton, PickleBall, User, Users, Men, Women } from '../../assets/images/svg';

// API Service
import { deleteTournamentService, publishUnpublishTournamentService } from '../../service/tournament.service';

// Redux-Functions
import { selectUser, selectFooterVisiblity } from "../../redux/userSlice";

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

const OverviewSection = (props) => {
    let { tournamentId } = useParams();
    const navigate = useNavigate();

    const user = useSelector(selectUser);
    const footerVisiblity = useSelector(selectFooterVisiblity);

    /**
        * @function deleteTournament
        * @params
        * @description used to delete tournament
    */
    const deleteTournament = async () => {
        try {
            const result = await deleteTournamentService(tournamentId);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                navigate('/tournament')
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function publishUnpublishTournament
        * @params
        * @description used to publish/unpublish tournament
    */
    const publishUnpublishTournament = async () => {
        try {
            const result = await publishUnpublishTournamentService(tournamentId);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                props?.fetchOrganizerSingleTournament()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function redirectEnterTournament
        * @params
        * @description used to navigate enter tournament
    */
    const redirectEnterTournament = () => {
        navigate('/enter-tournament', {
            state: {
                tournamentId
            }
        });
    }

    /**
        * @function renderLink
        * @params { attributes, content }
        * @description used to render link
    */
    const renderLink = ({ attributes, content }) => {
        const { href } = attributes;
        return <a href={href} target="_blank" rel="noopener noreferrer">
            {content}
        </a>
    };

    return <Container fluid>
        {props?.tournament &&
            <Row className='mb-4 pb-4 pb-lg-5'>
                <Col xs={12} lg={7}>
                    <div className='d-flex flex-column align-items-start'>
                        {moment(new Date(props?.tournament?.registration_end_date)).diff(moment(new Date()), 'days') >= 0 ?
                            <span className='bg-success text-light px-2 rounded-pill fs-14 fw-500'>Open</span>
                            :
                            <span className='bg-danger text-light px-2 rounded-pill fs-14 fw-500'>Closed</span>
                        }
                        <div className='mt-2'>
                            <CiCalendar className='text-primary me-2' size={20} />
                            <span className='fs-14 fw-400 text-secondary'>Registration Date:&nbsp;</span>
                            <span className='fs-14 fw-400 text-secondary'>{moment(new Date(props?.tournament?.registration_start_date)).format("DD/MM/YYYY")} to {moment(new Date(props?.tournament?.registration_end_date)).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className='mt-2'>
                            <CiCalendar className='text-primary me-2' size={20} />
                            <span className='fs-14 fw-400 text-secondary'>Tournament Date:&nbsp;</span>
                            <span className='fs-14 fw-400 text-secondary'>{moment(new Date(props?.tournament?.tournament_start_date)).format("DD/MM/YYYY")} to {moment(new Date(props?.tournament?.tournament_end_date)).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className='mt-2'>
                            <CiLocationOn className='text-primary me-2' size={20} />
                            <span className='fs-14 fw-500 text-secondary'>{props?.tournament?.address}</span>
                        </div>

                        {props?.tournament?.description &&
                            <div className='mt-4 d-flex flex-column align-items-start'>
                                <span className='fs-18 fw-600 mb-2'>Description</span>
                                <span className='fs-14 fw-400 text-secondary white-break-spaces overview-rich-text'>
                                    <Linkify options={{
                                        render: renderLink
                                    }}>
                                        {parse(props?.tournament?.description)}
                                    </Linkify>
                                </span>
                            </div>
                        }

                        {props?.tournament?.prizes &&
                            <div className='mt-4 d-flex flex-column align-items-start'>
                                <span className='fs-18 fw-600 mb-2'>Prizes</span>
                                <span className='fs-14 fw-400 text-secondary white-break-spaces overview-rich-text'>
                                    <Linkify options={{
                                        render: renderLink
                                    }}>
                                        {parse(props?.tournament?.prizes)}
                                    </Linkify>
                                </span>
                            </div>
                        }

                        {props?.tournament?.terms_and_condition &&
                            <div className='mt-4 d-flex flex-column align-items-start'>
                                <span className='fs-18 fw-600 mb-2'>Terms & Conditions</span>
                                <span className='fs-14 fw-400 text-secondary white-break-spaces overview-rich-text'>
                                    <Linkify options={{
                                        render: renderLink
                                    }}>
                                        {parse(props?.tournament?.terms_and_condition)}
                                    </Linkify>
                                </span>
                            </div>
                        }

                        {((user?.type === 'organizer' && props?.tournament?.organization_id === user?.id) || (user?.type === 'organizer' && user?.parent_id && props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                            <div className='mt-4 d-none d-lg-flex align-items-center'>
                                <span role='button' className='text-danger fw-bold px-3 me-2' onClick={() => ConfirmAlert('Are You Sure You Want To Delete Tournament', "You won't be able to revert this!", deleteTournament)}>Delete Tournament</span>
                                {props?.tournament?.is_published ?
                                    <div role='button' className='bg-danger bg-opacity-20 rounded py-1' onClick={publishUnpublishTournament}>
                                        <span className='text-danger fw-bold px-3 me-2'>Unpublish Tournament</span>
                                    </div>
                                    :
                                    <div role='button' className='bg-success bg-opacity-20 rounded py-1' onClick={publishUnpublishTournament}>
                                        <span className='text-success fw-bold px-3 me-2'>Publish Tournament</span>
                                    </div>
                                }
                            </div>
                        }

                        {user?.type === 'customer' && moment(new Date(props?.tournament?.registration_end_date)).diff(moment(new Date()), 'days') >= 0 && moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]') &&
                            <PrimaryButton className={classNames('mt-3 d-none d-lg-block position-fixed vw-44', {
                                'bottom-85': footerVisiblity,
                                'bottom-30': !footerVisiblity
                            })} onClick={redirectEnterTournament} disabled={!moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]')}>Join Tournament</PrimaryButton>
                        }
                        {!user && moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]') &&
                            <Link to='/login' className={classNames('text-decoration-none position-fixed vw-44', {
                                'bottom-85': footerVisiblity,
                                'bottom-30': !footerVisiblity,
                                "opacity-25 pe-none": !moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]')
                            })}>
                                <PrimaryButton className='mt-3 d-none d-lg-block' disabled={!moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]')}>Join Tournament</PrimaryButton>
                            </Link>
                        }
                        {user?.type === 'customer' && props?.tournament?.player_details?.length > 0 &&
                            <Link to='/receipts' className='text-decoration-none w-100'>
                                <div role='button' className='bg-danger bg-opacity-20 rounded py-2 d-none d-lg-block text-center w-100 mt-3'>
                                    <span className='text-primary fw-bold px-3 me-2'>Download Reciept</span>
                                </div>
                            </Link>
                        }
                    </div>
                </Col>
                <Col xs={12} lg={5} className='mt-3 mt-lg-0'>
                    <div className='d-flex flex-column align-items-start'>
                        <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                            <span className='fw-600'>Type of Sports</span>
                            {props?.tournament?.sports_type === "BADMINTON" &&
                                <div className='d-flex flex-column align-items-center text-light bg-primary rounded py-2 px-3 mt-3'>
                                    <Badminton className='img-fluid mt-2 mb-2' />
                                    <span>Badminton</span>
                                </div>
                            }
                            {props?.tournament?.sports_type === "PICKLEBALL" &&
                                <div className='d-flex flex-column align-items-center text-light bg-primary rounded py-2 px-3 mt-3'>
                                    <PickleBall className='img-fluid mt-2 mb-2' />
                                    <span>Pickleball</span>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <span className='fw-600'>Categories</span>
                        {props?.tournament?.category_type === "INDIVIDUAL" &&
                            <div className='d-flex align-items-center mt-2'>
                                <User className='me-2' />
                                Individuals Event
                            </div>
                        }
                        {props?.tournament?.category_type === "TEAM" &&
                            <div className='d-flex justify-content-between align-items-center mt-2 w-100'>
                                <div className='d-flex align-items-center'>
                                    <Users className='me-2' />
                                    Team Event
                                </div>
                                <span className='fw-600'>RM{props?.tournament?.team_event_price}</span>
                            </div>
                        }
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <span className='fw-600'>Type of Individual Event ({props?.tournament?.event_details?.length || 0})</span>
                        {props?.tournament?.event_details?.length > 0 && props?.tournament?.event_details?.map((item, index) => {
                            return <div className='d-flex flex-wrap flex-column w-100' key={index}>
                                <div className='d-flex align-items-center text-primary mt-2'>
                                    {(item?.event_type === 'MEN_SINGLE' || item?.event_type === 'MEN_DOUBLE' || item?.event_type === 'MIX_DOUBLE' || item?.event_type === 'MEN_3_3' || item?.event_type === 'MIX_3_3' || item?.event_type === 'JUNIOR_SINGLE' || item?.event_type === 'JUNIOR_DOUBLE') && <Men color='#FA5000' className='me-2' />}
                                    {(item?.event_type === 'WOMEN_SINGLE' || item?.event_type === 'WOMEN_DOUBLE' || item?.event_type === 'MIX_DOUBLE' || item?.event_type === 'WOMEN_3_3' || item?.event_type === 'MIX_3_3' || item?.event_type === 'JUNIOR_SINGLE' || item?.event_type === 'JUNIOR_DOUBLE') && <Women color='#FA5000' className='me-2' />}
                                    {item?.event_type && convertEventType(item?.event_type)}
                                </div>
                                <div className='d-flex justify-content-between align-items-center mt-2 w-100 fs-14 fw-400 text=secondary'>
                                    <span>
                                        {convertAgeGroup(item?.age_group, item?.age_value)}
                                    </span>
                                    {props?.tournament?.category_type === "INDIVIDUAL" && <span>RM{item?.price}</span>}
                                </div>
                                {item?.number_of_males > 0 && item?.event_type === 'MIX_3_3' &&
                                    <div className='d-flex justify-content-between align-items-center mt-2 w-100 fs-14 fw-400 text=secondary'>
                                        <span>Male</span>
                                        <span>{item?.number_of_males}</span>
                                    </div>
                                }
                                {item?.number_of_females > 0 && item?.event_type === 'MIX_3_3' &&
                                    <div className='d-flex justify-content-between align-items-center mt-2 w-100 fs-14 fw-400 text=secondary'>
                                        <span>Female</span>
                                        <span>{item?.number_of_females}</span>
                                    </div>
                                }
                                {index !== props?.tournament?.event_details?.length - 1 && <hr className='w-100' />}
                            </div>
                        })}
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Organizer</span>
                            {((user?.type === 'organizer' && props?.tournament?.organization_id === user?.id) || (user?.type === 'organizer' && user?.parent_id && props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                                <FiEdit3 role='button' size={20} onClick={props?.handleUpdateTournamentModal} />
                            }
                        </div>
                        <span className='text-primary fw-600'>{props?.tournament?.organizer?.name}</span>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Location</span>
                            {((user?.type === 'organizer' && props?.tournament?.organization_id === user?.id) || (user?.type === 'organizer' && user?.parent_id && props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                                <FiEdit3 role='button' size={20} onClick={props?.handleUpdateTournamentModal} />
                            }
                        </div>
                        <span className='text-secondary'>{props?.tournament?.address}</span>
                    </div>

                    <div className='d-flex flex-column align-items-start mb-4 border rounded w-100 px-3 py-2'>
                        <div className='d-flex justify-content-between align-items-center w-100 mb-2'>
                            <span className='fs-18 fw-600'>Contacts</span>
                            {((user?.type === 'organizer' && props?.tournament?.organization_id === user?.id) || (user?.type === 'organizer' && user?.parent_id && props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                                <FiEdit3 role='button' size={20} onClick={props?.handleUpdateTournamentModal} />
                            }
                        </div>
                        <span className='text-secondary'>{props?.tournament?.contact}</span>
                        <span className='text-secondary fs-14'>{props?.tournament?.phone_number}</span>
                    </div>

                    {((user?.type === 'organizer' && props?.tournament?.organization_id === user?.id) || (user?.type === 'organizer' && user?.parent_id && props?.tournament?.subOrganizers?.length > 0 && props?.tournament?.subOrganizers?.some(item => item?.id === user?.id))) &&
                        <div className='mb-4 d-flex justify-content-between flex-wrap d-lg-none align-items-center'>
                            <span role='button' className='text-danger fw-bold px-3 me-2' onClick={() => ConfirmAlert('Are You Sure You Want To Delete Tournament', "You won't be able to revert this!", deleteTournament)}>Delete</span>
                            {props?.tournament?.is_published ?
                                <div role='button' className='bg-danger bg-opacity-20 rounded py-1' onClick={publishUnpublishTournament}>
                                    <span className='text-danger fw-bold px-3 me-2'>Unpublish</span>
                                </div>
                                :
                                <div role='button' className='bg-success bg-opacity-20 rounded py-1' onClick={publishUnpublishTournament}>
                                    <span className='text-success fw-bold px-3 me-2'>Publish</span>
                                </div>
                            }
                        </div>
                    }

                    {user?.type === 'customer' && moment(new Date(props?.tournament?.registration_end_date)).diff(moment(new Date()), 'days') >= 0 && moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]') &&
                        <PrimaryButton className={classNames('mt-3 d-lg-none position-fixed vw-96', {
                            'bottom-xs-210': footerVisiblity,
                            'bottom-30': !footerVisiblity
                        })} onClick={redirectEnterTournament} disabled={!moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]')}>Join Tournament</PrimaryButton>
                    }
                    {!user && moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]') &&
                        <Link to='/login' className={classNames('text-decoration-none position-fixed vw-96', {
                            'bottom-xs-210': footerVisiblity,
                            'bottom-30': !footerVisiblity,
                            "opacity-25 pe-none": !moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]')
                        })}>
                            <PrimaryButton className='mt-3 d-lg-none' disabled={!moment(moment().format('YYYY-MM-DD')).isBetween(props?.tournament?.registration_start_date, props?.tournament?.registration_end_date, undefined, '[]')}>Join Tournament</PrimaryButton>
                        </Link>
                    }
                    {user?.type === 'customer' && props?.tournament?.player_details?.length > 0 &&
                        <Link to='/receipts' className='text-decoration-none w-100'>
                            <div role='button' className='bg-danger bg-opacity-20 rounded py-2 d-lg-none text-center'>
                                <span className='text-primary fw-bold px-3 me-2'>Download Reciept</span>
                            </div>
                        </Link>
                    }
                </Col>
            </Row>
        }
    </Container>
};

export default OverviewSection;