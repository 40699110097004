import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

// Custom Component
import ErrorAlert from '../../components/Alert/ErrorAlert';
import PaymentSuccessModal from "../../components/Modal/PaymentSuccessModal";

// API Service
import { teamPlayerService } from '../../service/tournament.service';

const PaymentSuccess = () => {
    let { tournamentId, teamId } = useParams();

    const [tournament, setTournament] = useState();
    const [paymentSuccessModal, setPaymentSuccessModal] = useState(false)

    /**
        * @function fetchCustomerSingleTournament
        * @params
        * @description fetch the single tournament details for customer
    */
    const fetchCustomerSingleTournament = async () => {
        try {
            const result = await teamPlayerService({
                id: tournamentId,
                teamId
            });
            if (result?.data?.status) {
                setTournament(result?.data?.data)
                handlePaymentSuccessModal()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchCustomerSingleTournament()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId])

    /**
        * @function handlePaymentSuccessModal
        * @params
        * @description set the visibility of payment success modal
    */
    const handlePaymentSuccessModal = () => {
        setPaymentSuccessModal(!paymentSuccessModal)
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Payment Success | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        {/* Payment Success Modal */}
        <PaymentSuccessModal show={paymentSuccessModal} handleClose={handlePaymentSuccessModal} tournament={tournament} size='lg' />
    </HelmetProvider>;
};

export default PaymentSuccess;