import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Form } from 'react-bootstrap';
import { useTable } from "react-table";
import { FiEdit3 } from "react-icons/fi";
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// Custom Component
import { PrimaryOutlineButton } from "../../components/Button";
import AddTShirtModal from '../../components/Modal/AddTShirtModal';
import EditTShirtModal from '../../components/Modal/EditTShirtModal';

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const TShirtSetting = () => {
    const user = useSelector(selectUser);

    const [addTShirtModal, setAddTShirtModal] = useState(false)
    const [editTShirtModal, setEditTShirtModal] = useState(false)

    /**
        * @function handleAddTShirtModal
        * @params
        * @description set the visibility of add t-shirt modal
    */
    const handleAddTShirtModal = () => {
        setAddTShirtModal(!addTShirtModal)
    }

    /**
        * @function handleEditTShirtModal
        * @params
        * @description set the visibility of edit t-shirt modal
    */
    const handleEditTShirtModal = () => {
        setEditTShirtModal(!editTShirtModal)
    }

    function useInstance(instance) {
        const { allColumns } = instance;

        let rowSpanHeaders = [];

        allColumns.forEach((column, i) => {
            const { id, enableRowSpan } = column;

            if (enableRowSpan !== undefined) {
                rowSpanHeaders = [
                    ...rowSpanHeaders,
                    { id, topCellValue: null, topCellIndex: 0 }
                ];
            }
        });

        Object.assign(instance, { rowSpanHeaders });
    }

    const origData = [
        {
            name: "T-Shirt 1",
            tournament: "T-Shirt 1",
            sizes: [
                {
                    name: "S",
                    quantity: 10,
                    enable: true
                },
                {
                    name: "M",
                    quantity: 20,
                    enable: true
                },
                {
                    name: "L",
                    quantity: 50,
                    enable: true
                }
            ]
        }
    ];

    const newData = [];

    origData.forEach(actorObj => {
        actorObj.sizes.forEach(size => {
            newData.push({
                name: actorObj.name,
                tournament: actorObj.tournament,
                size: size.name,
                quantity: size.quantity,
                enable: size.enable
            });
        });
    });

    // eslint-disable-next-line
    const data = React.useMemo(() => newData, []);

    const columns = React.useMemo(() => [
        {
            Header: "T-Shirt",
            accessor: "name",
            enableRowSpan: true,
            minWidth: 150
        },
        {
            Header: "Tournament",
            accessor: "tournament",
            enableRowSpan: true
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Total Quantity",
            accessor: "quantity"
        },
        {
            Header: "T-Shirt Option Status",
            accessor: 'enable',
            Cell: ({ value }) => <Form.Check
                type="switch"
                checked={value}
            />
        },
        {
            Header: 'Actions',
            Cell: ({ row }) => (
                <FiEdit3 role='button' className='text-secondary' size={20} onClick={handleEditTShirtModal} />
            ),
            enableRowSpan: true
        }
        // eslint-disable-next-line
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        rowSpanHeaders
    } = useTable({ columns, data }, hooks => {
        hooks.useInstance.push(useInstance);
    });

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>T-Shirt Setting | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-25 fw-600 mt-4'>T-Shirts</p>
            {!user?.parent_id &&
                <div>
                    <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={handleAddTShirtModal}>Add T-Shirts</PrimaryOutlineButton>
                </div>
            }
        </div>
        <div className='d-flex flex-column align-items-start border rounded mb-3'>
            <div className='d-flex justify-content-between align-items-center p-3 ps-0 w-100'>
                <div className='d-flex align-items-center'>
                    <Form.Check
                        className='me-3'
                        type="switch"
                        label={<span className='fw-600'>Enable</span>}
                    />
                    <span className='fs-12 fw-600 text-primary px-2 py-1 bg-danger bg-opacity-10 rounded'>50 t-shirts</span>
                </div>
                <div className='d-flex align-items-center'>
                    <Form.Group className='d-none d-lg-block me-lg-3'>
                        <Form.Select>
                            <option value="">Size</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="XXL">XXL</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='d-none d-lg-block me-lg-3'>
                        <Form.Select>
                            <option value="">Tournament</option>
                        </Form.Select>
                    </Form.Group>
                    <span role='button' className='fs-14 fw-600 text-primary p-2 bg-danger bg-opacity-10 rounded'>Export CSV</span>
                </div>
            </div>
            <div className='d-flex flex-column d-lg-none align-items-center px-3 w-100'>
                <Form.Group className='mb-3 mb-lg-0 w-100'>
                    <Form.Select>
                        <option value="">Size</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="XXL">XXL</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className='mb-3 mb-lg-0 w-100'>
                    <Form.Select>
                        <option value="">Tournament</option>
                    </Form.Select>
                </Form.Group>
            </div>
            <div className='d-none d-lg-block overflow-x-auto w-100'>
                <table className='table table-bordered mb-4' {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className='fs-12 fw-500' {...column.getHeaderProps()} style={{
                                        minWidth: column.minWidth || 'auto',
                                        padding: "8px 10px",
                                        backgroundColor: "#EAECF0"
                                    }}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);

                            for (let j = 0; j < row.allCells.length; j++) {
                                let cell = row.allCells[j];
                                let rowSpanHeader = rowSpanHeaders.find(
                                    x => x.id === cell.column.id
                                );

                                if (rowSpanHeader !== undefined) {
                                    if (
                                        rowSpanHeader.topCellValue === null ||
                                        rowSpanHeader.topCellValue !== cell.value
                                    ) {
                                        cell.isRowSpanned = false;
                                        rowSpanHeader.topCellValue = cell.value;
                                        rowSpanHeader.topCellIndex = i;
                                        cell.rowSpan = 1;
                                    } else {
                                        rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
                                        cell.isRowSpanned = true;
                                    }
                                }
                            }
                            return null;
                        })}
                        {rows.map(row => {
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        if (cell.isRowSpanned) return null;
                                        else
                                            return (
                                                <td
                                                    style={{
                                                        padding: "8px 10px"
                                                    }}
                                                    className={classNames('fs-14', {
                                                        'align-top': cell.rowSpan
                                                    })}
                                                    rowSpan={cell.rowSpan}
                                                    {...cell.getCellProps()}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className='p-3 w-100'>
                {origData?.length > 0 && origData?.map((item, index) => {
                    return <div className='d-flex flex-column align-items-start d-lg-none w-100 border rounded p-3 mb-3' key={`tshirt-${index}`}>
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className='d-grid'>
                                <span className='fw-600 text-truncate'>{item?.name}</span>
                            </div>
                            <FiEdit3 role='button' className='text-secondary' size={20} onClick={handleEditTShirtModal} />
                        </div>
                        <span className='fs-14 text-secondary'>Tournament</span>
                        <span className='fw-600'>{item?.tournament}</span>
                        <hr className='w-100' />
                        {item?.sizes?.length > 0 && item?.sizes?.map((size, sizeIndex) => {
                            return <div className='d-flex justify-content-between align-items-center w-100' key={`size-${index}-${sizeIndex}`}>
                                <span className='fw-600'>{size?.name}</span>
                                <span className='text-secondary'>{size?.quantity}</span>
                                <Form.Check
                                    type="switch"
                                    checked={size?.enable}
                                />
                            </div>
                        })}

                    </div>
                })}
            </div>
        </div>

        {/* Add T-Shirt Modal */}
        <AddTShirtModal show={addTShirtModal} handleClose={handleAddTShirtModal} size='lg' />

        {/* Edit T-Shirt Modal */}
        <EditTShirtModal show={editTShirtModal} handleClose={handleEditTShirtModal} size='lg' />
    </HelmetProvider>
};

export default TShirtSetting;