import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

// Custom Component
import { PrimaryOutlineButton } from "../../components/Button";
import MyTournament from "../../components/Tournament/MyTournament";
import ErrorAlert from '../../components/Alert/ErrorAlert';
import CreateTournamentModal from '../../components/Modal/Tournament/CreateTournamentModal';

// API Service
import { allTournamentService } from '../../service/tournament.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

const Tournament = () => {
    const user = useSelector(selectUser);

    const [myTournamentList, setMyTournamentList] = useState([])
    const [pageLoad, setPageLoad] = useState(true);
    const [createTournamentModal, setCreateTournamentModal] = useState(false)

    const perPage = 12;

    /**
        * @function fetchTournamentList
        * @params page
        * @description fetch the list of tournament
    */
    const fetchTournamentList = async (page) => {
        try {
            const result = await allTournamentService({
                paginate: 1,
                page,
                perPage,
                keyword: ''
            });
            if (result?.data?.status) {
                setMyTournamentList(myTournamentList.concat(result?.data?.data?.tournament_lists))
                if (result?.data?.data?.pagination?.current_page === result?.data?.data?.pagination?.total_pages) {
                    setPageLoad(false)
                } else {
                    setPageLoad(true)
                }
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchTournamentList(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
        * @function handleCreateTournamentModal
        * @params
        * @description set the visibility of create tournament modal
    */
    const handleCreateTournamentModal = () => {
        setCreateTournamentModal(!createTournamentModal)
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>My Tournament | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-25 fw-600 mt-4'>My Tournaments</p>
            {!user?.parent_id &&
                <div>
                    <PrimaryOutlineButton className='fs-14 fw-500' onClick={handleCreateTournamentModal}>Create Tournament</PrimaryOutlineButton>
                </div>
            }
        </div>

        <MyTournament fetchTournamentList={fetchTournamentList} myTournamentList={myTournamentList} pageLoad={pageLoad} />

        {/* Create Tournament Modal */}
        <CreateTournamentModal show={createTournamentModal} handleClose={handleCreateTournamentModal} fetchTournamentList={fetchTournamentList} size='lg' />
    </HelmetProvider>
};

export default Tournament;