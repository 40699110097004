import { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Custom Component
import { TournamentCard } from "../Card";
import NoFavoriteTournament from "./NoFavoriteTournament";
import ErrorAlert from '../Alert/ErrorAlert';

// API Service
import { allCustomerFavoriteService, allOrganizerFavoriteService } from '../../service/tournament.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

const MyFavoriteTournament = () => {
    const user = useSelector(selectUser);

    const [favoriteTournamentList, setFavoriteTournamentList] = useState([])

    /**
        * @function fetchCustomerFavoriteTournamentList
        * @params
        * @description fetch the list of favorite tournament for customer
    */
    const fetchCustomerFavoriteTournamentList = async () => {
        try {
            const result = await allCustomerFavoriteService({
                paginate: 1,
                page: 1,
                perPage: 10
            });
            if (result?.data?.status) {
                setFavoriteTournamentList(result?.data?.data?.tournament_list)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchOrganizerFavoriteTournamentList
        * @params
        * @description fetch the list of favorite tournament for organizer
    */
    const fetchOrganizerFavoriteTournamentList = async () => {
        try {
            const result = await allOrganizerFavoriteService({
                paginate: 1,
                page: 1,
                perPage: 10
            });
            if (result?.data?.status) {
                setFavoriteTournamentList(result?.data?.data?.tournament_list)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'customer') {
            fetchCustomerFavoriteTournamentList()
        } else if (user?.type === 'organizer') {
            fetchOrganizerFavoriteTournamentList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Row className='my-3'>
        {favoriteTournamentList.length > 0 ? favoriteTournamentList.map((item, index) => {
            return <TournamentCard item={item} key={index} />
        })
            :
            <NoFavoriteTournament />
        }
    </Row>;
};

export default MyFavoriteTournament;