import axios from "axios"

/**
    * @function allTournamentService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all tournament for organizer
*/
export const allTournamentService = async ({ paginate, page, perPage, keyword }) => {
    try {
        const result = await axios.get(`/organizer/tournament/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addTournamentService
    * @param data
    * @description This function is used to add tournament
*/
export const addTournamentService = async (data) => {
    try {
        const result = await axios.post(`/organizer/tournament/create`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateTournamentService
    * @param { id, data }
    * @description This function is used to update tournament
*/
export const updateTournamentService = async ({ id, data }) => {
    try {
        const result = await axios.post(`/organizer/tournament/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getOrganizerSingleTournamentService
    * @param id
    * @description This function is used to get single tournament details for organizer
*/
export const getOrganizerSingleTournamentService = async (id) => {
    try {
        const result = await axios.get(`/organizer/tournament/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getCustomerSingleTournamentService
    * @param id
    * @description This function is used to get single tournament details for customer
*/
export const getCustomerSingleTournamentService = async (id) => {
    try {
        const result = await axios.get(`/customer/tournament/single/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getDashboardSingleTournamentService
    * @param id
    * @description This function is used to get single tournament details for dashboard
*/
export const getDashboardSingleTournamentService = async (id) => {
    try {
        const result = await axios.get(`/customer/dashboard/single-tournament/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function deleteTournamentService
    * @param id
    * @description This function is used to delete tournament
*/
export const deleteTournamentService = async (id) => {
    try {
        const result = await axios.delete(`/organizer/tournament/delete/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function publishUnpublishTournamentService
    * @param id
    * @description This function is used to publish/unpublish tournament
*/
export const publishUnpublishTournamentService = async (id) => {
    try {
        const result = await axios.post(`/organizer/tournament/publish/${id}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function enterTournamentService
    * @param data
    * @description This function is used to enter tournament for player
*/
export const enterTournamentService = async (data) => {
    try {
        const result = await axios.post(`/customer/tournament/enter-tournament`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function editCustomerPlayerService
    * @param { id, data }
    * @description This function is used to edit player for customer while entering tournament
*/
export const editCustomerPlayerService = async ({ id, data }) => {
    try {
        const result = await axios.post(`/customer/tournament/edit-enter-tournament/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function removePlayerService
    * @param tournamentId, playerId
    * @description This function is used to remove player
*/
export const removePlayerService = async (tournamentId, playerId) => {
    try {
        const result = await axios.post(`/customer/tournament/remove-player/${tournamentId}/${playerId}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addPlayerService
    * @param tournamentId, data
    * @description This function is used to add player for organizer
*/
export const addPlayerService = async (data) => {
    try {
        const result = await axios.post(`/organizer/tournament/add-player`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allEnteredTournamentHistoryService
    * @param { paginate, page, perPage }
    * @description This function is used to get all entered tournament history for customer
*/
export const allEnteredTournamentHistoryService = async ({ paginate, page, perPage }) => {
    try {
        const result = await axios.get(`/customer/tournament/entered-tournament?paginate=${paginate}&page=${page}&perPage=${perPage}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allPlayerOrganizerService
    * @param { id, gender, event_type, paginate, page, perPage, keyword }
    * @description This function is used to get all player of a tournament for organizer
*/
export const allPlayerOrganizerService = async ({ id, gender, event_type, paginate, page, perPage, keyword }) => {
    try {
        let result
        if (gender && event_type) {
            result = await axios.get(`/organizer/tournament/single/players/${id}?gender=${gender}&event_type=${event_type}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else if (gender) {
            result = await axios.get(`/organizer/tournament/single/players/${id}?gender=${gender}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else if (event_type) {
            result = await axios.get(`/organizer/tournament/single/players/${id}?event_type=${event_type}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else {
            result = await axios.get(`/organizer/tournament/single/players/${id}?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        }
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allPlayerCustomerService
    * @param { id, paginate, page, perPage, keyword }
    * @description This function is used to get all player of a tournament for customer
*/
export const allPlayerCustomerService = async ({ id, paginate, page, perPage, keyword }) => {
    try {
        const result = await axios.get(`/customer/players/list/${id}?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allEarningService
    * @param { id, gender, event_type, paginate, page, perPage, keyword }
    * @description This function is used to get all earning of a tournament for organizer
*/
export const allEarningService = async ({ id, gender, event_type, paginate, page, perPage, keyword }) => {
    try {
        let result
        if (gender && event_type) {
            result = await axios.get(`/organizer/tournament/single/earnings/${id}?gender=${gender}&event_type=${event_type}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else if (gender) {
            result = await axios.get(`/organizer/tournament/single/earnings/${id}?gender=${gender}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else if (event_type) {
            result = await axios.get(`/organizer/tournament/single/earnings/${id}?event_type=${event_type}&paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        } else {
            result = await axios.get(`/organizer/tournament/single/earnings/${id}?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        }
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addCustomerFavoriteService
    * @param id
    * @description This function is used to add favorite tournament for customer
*/
export const addCustomerFavoriteService = async (id) => {
    try {
        const result = await axios.post(`/customer/favorite/add/${id}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function addOrganizerFavoriteService
    * @param id
    * @description This function is used to add favorite tournament for organizer
*/
export const addOrganizerFavoriteService = async (id) => {
    try {
        const result = await axios.post(`/organizer/favorite/add/${id}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allCustomerFavoriteService
    * @param { paginate, page, perPage }
    * @description This function is used to get all favorite tournament for customer
*/
export const allCustomerFavoriteService = async ({ paginate, page, perPage }) => {
    try {
        const result = await axios.get(`/customer/favorite/tournaments?paginate=${paginate}&page=${page}&perPage=${perPage}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allOrganizerFavoriteService
    * @param { paginate, page, perPage }
    * @description This function is used to get all favorite tournament for organizer
*/
export const allOrganizerFavoriteService = async ({ paginate, page, perPage }) => {
    try {
        const result = await axios.get(`/organizer/favorite/tournaments?paginate=${paginate}&page=${page}&perPage=${perPage}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleReceiptOrganizerService
    * @param { customerId, tournamentId }
    * @description This function is used to get single receipt info for organizer
*/
export const singleReceiptOrganizerService = async ({ customerId, tournamentId }) => {
    try {
        const result = await axios.get(`/organizer/tournament/single-receipt/${customerId}/${tournamentId}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allReceiptService
    * @param { paginate, page, perPage, keyword, receipt_no }
    * @description This function is used to get all receipt for customer
*/
export const allReceiptService = async ({ paginate, page, perPage, keyword, receipt_no }) => {
    try {
        let result
        result = await axios.get(`/customer/tournament/receipt-list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}&receipt_no=${receipt_no}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function singleReceiptCustomerService
    * @param { receipt_no, tournamentId }
    * @description This function is used to get single receipt info for customer
*/
export const singleReceiptCustomerService = async ({ receipt_no, tournamentId }) => {
    try {
        const result = await axios.get(`/customer/tournament/single-receipt/${receipt_no}/${tournamentId}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function publishPlayerService
    * @param id
    * @description This function is used to publish/unpublish player of a tournament
*/
export const publishPlayerService = async (id) => {
    try {
        const result = await axios.post(`/organizer/tournament/publish-player/${id}`, {})
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function withdrawPlayerService
    * @param id
    * @description This function is used to withdraw player
*/
export const withdrawPlayerService = async (id) => {
    try {
        const result = await axios.delete(`/organizer/tournament/player-withdraw/${id}`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function editOrganizerPlayerService
    * @param { id, data }
    * @description This function is used to edit player for organizer
*/
export const editOrganizerPlayerService = async ({ id, data }) => {
    try {
        const result = await axios.post(`/organizer/tournament/edit-player/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function teamPlayerService
    * @param { id, teamId }
    * @description This function is used to get all player of a team for customer
*/
export const teamPlayerService = async ({ id, teamId }) => {
    try {
        const result = await axios.get(`/customer/tournament/single-player/${id}/${teamId}`)
        return result;
    } catch (error) {
        return error;
    }
}