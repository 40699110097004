const Badminton = (props) => {
    return <svg width="25" height="24" className={props.className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.30756 15.4022L2.69922 15.9863L8.27549 21.7937L8.88383 21.2095L3.30756 15.4022Z" fill="#C30000" />
        <path d="M1.73728 16.9116C0.133679 18.4516 0.0820793 20.9998 1.62208 22.6034C3.16208 24.207 5.71029 24.2586 7.31389 22.7186L7.98449 22.0746L2.40788 16.2676L1.73728 16.9116Z" fill="#E8EBEC" />
        <path d="M5.67011 10.4274L5.91292 11.6912L3.94531 14.8358L4.49051 15.4038L6.63432 11.9776L7.41612 11.5382L7.62072 12.753L5.13451 16.0742L5.67032 16.6324L8.17012 13.2928L9.46432 13.0582L9.33812 14.6652L6.44732 17.4414L6.97592 17.9918L9.86672 15.2158L11.4777 15.1548L11.1909 16.4384L7.75292 18.8008L8.28872 19.3588L11.7081 17.0092L12.9135 17.2628L12.4427 18.0262L8.93272 20.0294L9.47792 20.5972L12.6997 18.7586L13.9527 19.0524L22.4887 15.0228L24.5015 12.3572L21.6011 12.1656L22.7815 9.24602L18.8769 9.42322L19.3037 5.62441L15.4907 5.89702L15.8259 2.00281L12.8609 3.06401L12.7869 0.158203L10.0419 2.06161L5.67011 10.4274Z" fill="#EDEDED" />
        <path d="M12.7985 0.158203L3.89844 14.8582L4.49844 15.3582L6.59844 11.9582L12.7985 0.158203Z" fill="#D9D9D9" />
        <path d="M5.09766 16.0586L5.69766 16.6586L8.19766 13.2586L15.7977 2.05859L9.99766 9.5655L5.09766 16.0586Z" fill="#D9D9D9" />
        <path d="M6.39844 17.4582L6.99844 18.0582L9.79844 15.2582L19.2985 5.6582L10.6984 13.3582L6.39844 17.4582Z" fill="#D9D9D9" />
        <path d="M7.69922 18.7578L8.29922 19.3578L11.6992 17.0578L22.7992 9.25781L11.1992 16.4578L7.69922 18.7578Z" fill="#D9D9D9" />
        <path d="M8.89844 20.0574L9.49844 20.6574L12.6984 18.7574L24.4985 12.3574L12.3984 18.0574L8.89844 20.0574Z" fill="#D9D9D9" />
    </svg>
}

export default Badminton;