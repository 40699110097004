import { Form } from 'react-bootstrap';
import { useFieldArray, Controller } from "react-hook-form";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";

// Custom Component
import ErrorText from '../../TextField/ErrorText'

const AgeGroup = ({ nestIndex, allWatchedValues, control, register, errors }) => {
    const { fields, remove, append } = useFieldArray({
        control,
        name: `event_details[${nestIndex}].age_groups`
    });

    return <div>
        {fields?.length > 0 && fields.map((item, index) => {
            return <div>
                <div className='d-flex flex-wrap align-items-start mt-4 position-relative' key={item.id}>
                    <div className={classNames({ 'd-flex flex-column flex-sm-row flex-sm-wrap align-items-start w-100 mb-3': allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3", 'd-flex flex-column flex-sm-row flex-sm-wrap align-items-start flex-grow-1': allWatchedValues?.event_details[nestIndex]?.event_type !== "MIX_3_3" })}>
                        <div className={classNames('flex-grow-1 w-xs-100 me-2', { 'me-xs-0': allWatchedValues?.event_details[nestIndex]?.event_type !== "MIX_3_3" })}>
                            <Form.Group>
                                <Form.Label className='fs-14 fw-500'>Age group</Form.Label>
                                <Form.Select
                                    {...register(`event_details[${nestIndex}].age_groups[${index}].age_group`, {
                                        required: "Age Group is required"
                                    })}>
                                    <option value="BELOW_AGE">Below age</option>
                                    <option value="ABOVE_AGE">Above age</option>
                                    {!(allWatchedValues?.event_details[nestIndex]?.event_type === "MEN_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "WOMEN_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "JUNIOR_SINGLE") && <option value="TOTAL_BELOW_AGE">Total below age</option>}
                                    {!(allWatchedValues?.event_details[nestIndex]?.event_type === "MEN_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "WOMEN_SINGLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "JUNIOR_SINGLE") && <option value="TOTAL_ABOVE_AGE">Total above age</option>}
                                    <option value="OPEN">Open</option>
                                    <option value="AMATEUR">Amateur</option>
                                    {(allWatchedValues?.event_details[nestIndex]?.event_type === "MEN_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "WOMEN_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "JUNIOR_DOUBLE") && <option value="SEMI_PRO">Semi-Pro</option>}
                                    <option value="BAKAT_BARU">Bakat Baru</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        {(allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "OPEN") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "AMATEUR") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "BAKAT_BARU") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "BAKAT_BARU") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group !== "SEMI_PRO") &&
                            <div className='w-xs-100 flex-grow-1 mt-3 mt-sm-0 me-2'>
                                <Form.Group>
                                    <Form.Label className='fs-14 fw-500'>Value of Age</Form.Label>
                                    <Controller
                                        control={control}
                                        name={`event_details[${nestIndex}].age_groups[${index}].age_value`}
                                        render={({ field }) => {
                                            return (
                                                <Form.Control
                                                    type="number"
                                                    {...field}
                                                    onChange={(e) => field.onChange(parseInt(e.target.value))}
                                                />
                                            )
                                        }}
                                        rules={{
                                            required: "Age Value is required"
                                        }}
                                    />
                                    {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.age_value && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.age_value?.message}</ErrorText>}
                                </Form.Group>
                            </div>
                        }
                        <div className={classNames({ 'w-xs-100 flex-grow-1 mt-3 mt-sm-0 me-2': allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3", 'd-none': allWatchedValues?.event_details[nestIndex]?.event_type !== "MIX_3_3" })}>
                            <Form.Group>
                                <Form.Label className='fs-14 fw-500'>Number of Male</Form.Label>
                                <Form.Control
                                    type="number"
                                    {...register(`event_details[${nestIndex}].age_groups[${index}].number_of_males`, {
                                        required: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "Number of Male is required" : false,
                                        max: {
                                            value: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? 2 : 3,
                                            message: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "You can't add more than 2 male" : "You can't add more than 3 male"
                                        },
                                        min: {
                                            value: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? 1 : 0,
                                            message: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "You need to add at least 1 male" : ""
                                        }
                                    })}
                                />
                                {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.number_of_males && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.number_of_males?.message}</ErrorText>}
                            </Form.Group>
                        </div>
                        <div className={classNames({ 'w-xs-100 flex-grow-1 mt-3 mt-sm-0 me-2': allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3", 'd-none': allWatchedValues?.event_details[nestIndex]?.event_type !== "MIX_3_3" })}>
                            <Form.Group>
                                <Form.Label className='fs-14 fw-500'>Number of Female</Form.Label>
                                <Form.Control
                                    type="number"
                                    {...register(`event_details[${nestIndex}].age_groups[${index}].number_of_females`, {
                                        required: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "Number of Female is required" : false,
                                        max: {
                                            value: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? 2 : 3,
                                            message: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "You can't add more than 2 female" : "You can't add more than 3 female"
                                        },
                                        min: {
                                            value: 1,
                                            message: allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" ? "You need to add at least 1 female" : ""
                                        }
                                    })}
                                />
                                {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.number_of_females && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.number_of_females?.message}</ErrorText>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className={classNames('flex-grow-1', { "d-none": allWatchedValues?.category_type === "TEAM", 'mx-lg-2 mt-2 mt-lg-0': allWatchedValues?.event_details[nestIndex]?.event_type !== "MIX_3_3", 'me-2': allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_3_3" })}>
                        <Form.Group>
                            <Form.Label className='fs-14 fw-500'>Price</Form.Label>
                            <Form.Control
                                type="number"
                                {...register(`event_details[${nestIndex}].age_groups[${index}].price`, {
                                    required: allWatchedValues?.category_type === "INDIVIDUAL" ? "Price is required" : false
                                })}
                            />
                            {errors?.event_details?.length > 0 && errors?.event_details[nestIndex]?.age_groups[index]?.price && <ErrorText>{errors?.event_details[nestIndex]?.age_groups[index]?.price?.message}</ErrorText>}
                        </Form.Group>
                    </div>

                    {fields.length > 1 && <IoMdClose role='button' className='position-absolute top-0 end-0' color='red' size={20} onClick={() => remove(index)} />}
                </div>
                <Form.Check
                    type='checkbox'
                    className='mt-2'
                    label={<span role='button' className='fw-500 text-secondary'>
                        Allow State/District/National Player to join?
                    </span>}
                    id={`allow-join[${nestIndex}][${index}`}
                    required={(allWatchedValues?.event_details[nestIndex]?.event_type === "MEN_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "WOMEN_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "MIX_DOUBLE" || allWatchedValues?.event_details[nestIndex]?.event_type === "JUNIOR_DOUBLE") && (allWatchedValues?.event_details[nestIndex]?.age_groups[index]?.age_group === "SEMI_PRO")}
                    {...register(`event_details[${nestIndex}].age_groups[${index}].allow_proffesional_players`)}
                />
            </div>
        })}

        <div role='button' className='d-inline-flex align-items-center mt-4' onClick={() =>
            append({
                age_group: "BELOW_AGE",
                age_value: "",
                price: "",
                number_of_males: "",
                number_of_females: ""
            })
        }>
            <BsFillPlusCircleFill className='text-primary me-2' size={25} />
            <span className='text-primary fw-500'>Add more age group</span>
        </div>
    </div>
};

export default AgeGroup;