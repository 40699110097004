import { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';

// Custom Component
import DatePickerWithYearAndMonth from '../DatePickerWithYearAndMonth/DatePickerWithYearAndMonth';
import ErrorText from '../TextField/ErrorText'
import SuccessAlert from '../Alert/SuccessAlert'
import ErrorAlert from '../Alert/ErrorAlert'
import { PrimaryButton } from '../Button';

// API Service
import { editOrganizerPlayerService } from '../../service/tournament.service';

const EditPlayerModal = (props) => {
    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined

    const { register, control, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    const countryList = [
        "Malaysia",
        "Denmark",
        "China",
        "Thailand",
        "Singapore",
        "Indonesia",
        "Vietnam",
        "Philippines",
        "Cambodia",
        "India",
        "England",
        "Japan",
        "South Korea",
        "Taiwan",
        "Hong Kong",
        "Others"
    ]

    // for setting default values
    useEffect(() => {
        if (props?.selectedData) {
            let defaultValues = {};
            defaultValues.player_name = props?.selectedData?.player_name
            defaultValues.club_name = props?.selectedData?.club_name
            defaultValues.player_email = props?.selectedData?.player_email
            defaultValues.player_contact = props?.selectedData?.player_contact
            defaultValues.player_gender = props?.selectedData?.player_gender
            defaultValues.player_dob = new Date(props?.selectedData?.player_dob)
            defaultValues.player_idcard = props?.selectedData?.player_idcard
            defaultValues.nationality = props?.selectedData?.nationality
            defaultValues.proffesional_player = props?.selectedData?.proffesional_player
            reset({ ...defaultValues })
        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    /**
        * @function onSubmit
        * @params formdata
        * @description used to edit player details from organizer
    */
    const onSubmit = async (formdata) => {
        const data = new FormData()
        data.append('_method', 'PUT')
        data.append('player_name', formdata?.player_name)
        data.append('club_name', formdata?.club_name)
        data.append('player_email', formdata?.player_email)
        data.append('player_contact', formdata?.player_contact)
        data.append('player_gender', formdata?.player_gender)
        data.append('player_dob', moment(formdata?.player_dob).format("YYYY-MM-DD"))
        data.append('player_idcard', formdata?.player_idcard)
        data.append('nationality', formdata?.nationality)
        data.append('proffesional_player', formdata?.proffesional_player ? 1 : 0)
        try {
            setLoading(true)
            const result = await editOrganizerPlayerService({
                id: props?.selectedData?.id,
                data
            });
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                props?.fetchOrganizerPlayerList(props?.currentPage + 1)
                reset()
                props.handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <Modal show={props.show} onHide={props.handleClose} centered size="lg">
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-24 fw-600 border-0' closeButton>
                Edit Player
            </Modal.Header>
            <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
                <div className='w-100'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Player Name"
                                        {...register(`player_name`, {
                                            required: "Player name is required",
                                        })}
                                    />
                                    {errors?.player_name && <ErrorText>{errors?.player_name?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Club Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Club Name"
                                        {...register(`club_name`)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        {...register(`player_email`, {
                                            required: "Player Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        })}
                                    />
                                    {errors?.player_email && <ErrorText>{errors?.player_email?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player Contact Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter Player Contact Number"
                                        {...register(`player_contact`, {
                                            required: "Player Contact Number is required",
                                            minLength: {
                                                value: 9,
                                                message: "Contact Number should be 9 digit minimum"
                                            },
                                            maxLength: {
                                                value: 11,
                                                message: "Contact Number should be 11 digit maximum"
                                            }
                                        })}
                                    />
                                    {errors?.player_contact && <ErrorText>{errors?.player_contact?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player Gender</Form.Label>
                                    <Form.Select
                                        {...register(`player_gender`, {
                                            required: "Player Gender is required"
                                        })}
                                        disabled
                                    >
                                        <option value='MALE'>Male</option>
                                        <option value='FEMALE'>Female</option>
                                    </Form.Select>
                                    {errors?.player_gender && <ErrorText>{errors?.player_gender?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="d-flex flex-column align-items-start my-2">
                                    <Form.Label className='text-fs-14 fw-500'>Date of Birth</Form.Label>
                                    <Controller
                                        control={control}
                                        name={`player_dob`}
                                        render={({ field }) => (
                                            <DatePickerWithYearAndMonth
                                                {...field}
                                                selected={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                maxDate={new Date()}
                                            />
                                        )}
                                        rules={{ required: 'Date of Birth is required' }}
                                    />
                                    {errors?.player_dob && <ErrorText>{errors?.player_dob?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Player IC/Passport Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Player IC/Passport Number"
                                        {...register(`player_idcard`, {
                                            required: "Player IC/Passport Number is required",
                                        })}
                                    />
                                    {errors?.player_idcard && <ErrorText>{errors?.player_idcard?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="my-2">
                                    <Form.Label className='fs-14 fw-500'>Country</Form.Label>
                                    <Form.Select
                                        {...register(`nationality`, {
                                            required: "Player Country is required",
                                        })}
                                    >
                                        <option value="">Select player country</option>
                                        {countryList?.map((item, index) => {
                                            return <option value={item} key={index}>{item}</option>
                                        })}
                                    </Form.Select>
                                    {errors?.nationality && <ErrorText>{errors?.nationality?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Check
                            className='mb-2'
                            type='checkbox'
                            label={<span role='button' className='fs-14 fw-600 text-secondary'>
                                Are you a State/District/National Player?
                            </span>}
                            id={`proffesional_player`}
                            {...register(`proffesional_player`)}
                        />

                        <Form.Check
                            type='checkbox'
                            label={<span role='button' className='fs-14 fw-600 text-secondary'>
                                Declare that the information shared are true and confirm.
                            </span>}
                            id={`declare`}
                            required
                        />

                        <div className='d-flex justify-content-end align-items-end mt-4'>
                            <div>
                                <PrimaryButton className='fs-14 fw-500 px-5' disabled={loading} loading={loading} type="submit">Edit Player</PrimaryButton>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default EditPlayerModal;