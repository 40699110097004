import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Custom Component
import { TournamentCard } from '../../components/Card';
import ErrorAlert from '../../components/Alert/ErrorAlert';
import NoTournament from "../../components/Tournament/NoTournament";

// API Service
import { allTournamentService } from '../../service/tournament.service';
import { searchTournamentService } from '../../service/dashboard.service';

// Redux-Functions
import { selectSearchTerm } from "../../redux/tournamentSlice"
import { selectUser } from "../../redux/userSlice"

const SearchTournament = () => {
    const [searchTournamentList, setSearchTournamentList] = useState([])

    const searchTerm = useSelector(selectSearchTerm);
    const user = useSelector(selectUser);

    /**
        * @function fetchOrganizerSearchTournamentList
        * @params
        * @description fetch the list of search tournament for organizer
    */
    const fetchOrganizerSearchTournamentList = async () => {
        try {
            const result = await allTournamentService({
                paginate: '',
                page: '',
                perPage: '',
                keyword: searchTerm
            });
            if (result?.data?.status) {
                setSearchTournamentList(result?.data?.data?.tournament_lists)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchCustomerSearchTournamentList
        * @params
        * @description fetch the list of search tournament for customer
    */
    const fetchCustomerSearchTournamentList = async () => {
        try {
            const result = await searchTournamentService(searchTerm);
            if (result?.data?.status) {
                setSearchTournamentList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerSearchTournamentList();
        } else {
            fetchCustomerSearchTournamentList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Search Tournament | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-25 fw-600 mt-4'>Showing Results For "{searchTerm}"</p>
        </div>
        {searchTournamentList?.length > 0 ?
            <Row>
                {searchTournamentList?.map((item, index) => {
                    return <TournamentCard item={item} key={index} />
                })}
            </Row>
            :
            <NoTournament search={true} />
        }
    </HelmetProvider>
};

export default SearchTournament;