import { Nav, Button } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { IoMdClose } from "react-icons/io";
import classNames from "classnames";

// Images
import { logo } from "../../assets/images";
// import { Home, Trophy, Tshirt, Wallet, Bank, SubOrganizer, Receipt } from "../../assets/images/svg";
import { Home, Trophy, Wallet, Bank, SubOrganizer, Receipt } from "../../assets/images/svg";

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const Menu = (props) => {
    const location = useLocation();

    const user = useSelector(selectUser)

    const navItemList = [
        {
            logo: <Home className="me-2" color={location.pathname === '/' && 'white'} />,
            text: 'Home',
            link: '/',
            visibility: true
        },
        {
            logo: <Trophy className="me-2" color={location.pathname === '/tournament' && 'white'} />,
            text: 'Tournaments',
            link: '/tournament',
            visibility: user?.type === 'organizer'
        },
        // {
        //     logo: <Tshirt className="me-2" color={location.pathname === '/tshirt-setting' && 'white'} />,
        //     text: 'T-Shirt Setting',
        //     link: '/tshirt-setting',
        //     visibility: user?.type === 'organizer'
        // },
        {
            logo: <Wallet className="me-2" color={location.pathname === '/wallet' && 'white'} />,
            text: 'Wallet',
            link: '/wallet',
            visibility: user?.type === 'organizer'
        },
        {
            logo: <Bank className="me-2" color={location.pathname === '/bank-account' && 'white'} />,
            text: 'Bank Account',
            link: '/bank-account',
            visibility: user?.type === 'organizer'
        },
        {
            logo: <SubOrganizer className="me-2" color={location.pathname === '/sub-organizer' && 'white'} />,
            text: 'Sub-Organizer',
            link: '/sub-organizer',
            visibility: user?.type === 'organizer' && !user?.parent_id
        },
        {
            logo: <Trophy className="me-2" color={location.pathname === '/tournament-history' && 'white'} />,
            text: 'Tournaments History',
            link: '/tournament-history',
            visibility: user?.type === 'customer'
        },
        {
            logo: <Receipt className="me-2" color={location.pathname === '/receipts' && 'white'} />,
            text: 'Receipts',
            link: '/receipts',
            visibility: user?.type === 'customer'
        }
    ]

    return <>
        <div className="mt-3">
            <Button
                variant="link"
                onClick={props.toggle}
                style={{ color: "#fff" }}
                className="mt-2 me-3 p-0 float-end d-lg-none"
            >
                <IoMdClose size={25} />
            </Button>
            <NavLink to='/'>
                <img className="img-fluid" src={logo} alt="AFA" />
            </NavLink>
        </div>

        <Nav className="flex-column flex-grow-1 pt-2 mt-3">
            {navItemList?.filter(item => item?.visibility)?.map((item, index) => {
                return <Nav.Item className={classNames("rounded", { "active": location.pathname === item.link })} key={index}>
                    <NavLink to={item.link} className={classNames("nav-link d-lg-none", { "text-light": location.pathname === item.link, "text-secondary": location.pathname !== item.link, })} onClick={props.toggle}>
                        {item.logo}
                        {item.text}
                    </NavLink>
                    <NavLink to={item.link} className={classNames("nav-link d-none d-lg-block", { "text-light": location.pathname === item.link, "text-secondary": location.pathname !== item.link, })}>
                        {item.logo}
                        {item.text}
                    </NavLink>
                </Nav.Item>
            })}

            {/* <Nav.Item className="mt-auto mb-3 rounded">
                <Nav.Link href="/" className="text-secondary">
                    <Support className="me-2" />
                    Support
                </Nav.Link>
            </Nav.Item> */}
        </Nav>
    </>
}

export default Menu;