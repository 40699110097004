import { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import { IoMdClose } from "react-icons/io";
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import moment from 'moment';
import classNames from "classnames";

// Custom Component
import { PrimaryButton } from '../Button';
import DatePickerWithYearAndMonth from '../DatePickerWithYearAndMonth/DatePickerWithYearAndMonth';
import ErrorText from '../TextField/ErrorText';
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';

// API Service
import { addPlayerService } from '../../service/tournament.service';

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

const AddPlayerModal = (props) => {
    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
        defaultValues: {
            player_details: [
                {
                    player_name: "",
                    club_name: "",
                    player_email: "",
                    player_contact: "",
                    player_gender: "MALE",
                    player_dob: "",
                    player_idcard: "",
                    nationality: ""
                }
            ]
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "player_details"
    });

    const watchEventType = watch("event_type")
    const allWatchedValues = watch({
        nest: true
    });

    useEffect(() => {
        if (watchEventType && props?.tournament?.category_type === "INDIVIDUAL") {
            if ((watchEventType === "MEN_SINGLE" || watchEventType === "WOMEN_SINGLE" || watchEventType === "JUNIOR_SINGLE") && fields?.length > 1) {
                setValue('player_details', allWatchedValues?.player_details.slice(0, 1));
            }
            if ((watchEventType === "MEN_DOUBLE" || watchEventType === "WOMEN_DOUBLE" || watchEventType === "MIX_DOUBLE" || watchEventType === "JUNIOR_DOUBLE") && fields?.length > 2) {
                setValue('player_details', allWatchedValues?.player_details.slice(0, 2));
            }
            if ((watchEventType === "MEN_3_3" || watchEventType === "WOMEN_3_3" || watchEventType === "MIX_3_3") && fields?.length > 3) {
                setValue('player_details', allWatchedValues?.player_details.slice(0, 3));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.tournament, watchEventType])

    const countryList = [
        "Malaysia",
        "Denmark",
        "China",
        "Thailand",
        "Singapore",
        "Indonesia",
        "Vietnam",
        "Philippines",
        "Cambodia",
        "India",
        "England",
        "Japan",
        "South Korea",
        "Taiwan",
        "Hong Kong",
        "Others"
    ]

    const reactSelectCustomStyle = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: state.isFocused && "1px solid #FA5000",
            boxShadow: state.isFocused && "0px 0px 2px #FA5000",
            "&:hover": {
                border: "1px solid #FA5000",
                boxShadow: "0px 0px 2px #FA5000"
            }
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#FA5000' : 'white',
                "&:hover": {
                    backgroundColor: "#FA5000",
                    color: 'white'
                }
            }
        }
    }

    /**
        * @function onSubmit
        * @params formdata
        * @description used to add player from organizer
    */
    const onSubmit = async (formdata) => {
        if (watchEventType && props?.tournament?.category_type === "INDIVIDUAL") {
            if ((watchEventType === "MEN_SINGLE" || watchEventType === "WOMEN_SINGLE" || watchEventType === "JUNIOR_SINGLE") && fields?.length !== 1) {
                ErrorAlert("You must add 1 player for Single Event");
                return
            }
            if ((watchEventType === "MEN_DOUBLE" || watchEventType === "WOMEN_DOUBLE" || watchEventType === "MIX_DOUBLE" || watchEventType === "JUNIOR_DOUBLE") && fields?.length !== 2) {
                ErrorAlert("You must add 2 player for Double Event");
                return
            }
            if ((watchEventType === "MEN_3_3" || watchEventType === "WOMEN_3_3" || watchEventType === "MIX_3_3") && fields?.length !== 3) {
                ErrorAlert("You must add 3 player for 3 on 3 Event");
                return
            }
        }
        const data = new FormData()
        data.append('tournament_id', props?.tournament?.id)
        if (formdata?.player_details?.length > 0) {
            formdata?.player_details?.forEach((item, index) => {
                data.append(`player_details[${index}][event_type]`, formdata?.event_type_age_group_value?.value?.split("|")[0])
                data.append(`player_details[${index}][age_group]`, formdata?.event_type_age_group_value?.value?.split("|")[1])
                data.append(`player_details[${index}][age_value]`, formdata?.event_type_age_group_value?.value?.split("|")[2])
                if (item?.player_name) {
                    data.append(`player_details[${index}][player_name]`, item?.player_name)
                }
                if (item?.club_name) {
                    data.append(`player_details[${index}][club_name]`, item?.club_name)
                }
                if (item?.player_email) {
                    data.append(`player_details[${index}][player_email]`, item?.player_email)
                }
                if (item?.player_contact) {
                    data.append(`player_details[${index}][player_contact]`, item?.player_contact)
                }
                if (item?.player_gender) {
                    data.append(`player_details[${index}][player_gender]`, item?.player_gender)
                }
                if (item?.player_dob) {
                    data.append(`player_details[${index}][player_dob]`, moment(item?.player_dob).format("YYYY-MM-DD"))
                }
                if (item?.player_idcard) {
                    data.append(`player_details[${index}][player_idcard]`, item?.player_idcard)
                }
                if (item?.nationality) {
                    data.append(`player_details[${index}][nationality]`, item?.nationality)
                }
                data.append(`player_details[${index}][proffesional_player]`, item?.proffesional_player ? 1 : 0)
            })
        }
        if (props?.tournament?.category_type === "TEAM" && formdata?.player_details?.length > 0) {
            data.append(`team_event_type[0]`, watchEventType)
            data.append(`team_event_age_group[0]`, formdata?.event_type_age_group_value?.value?.split("|")[1])
            if (watchEventType === 'OPEN' || watchEventType === 'AMATEUR' || watchEventType === 'BAKAT_BARU') {
                data.append(`team_event_age_value[0]`, 0)
            } else {
                data.append(`team_event_age_value[0]`, formdata?.event_type_age_group_value?.value?.split("|")[2])
            }
        }
        try {
            setLoading(true)
            const result = await addPlayerService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message);
                props?.setCurrentPage(0)
                await props?.fetchOrganizerPlayerList(1);
                await props?.fetchAllOrganizerPlayerList();
                props.handleClose();
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
        * @function handleClose
        * @params
        * @description used to close add player modal
    */
    const handleClose = async () => {
        reset()
        props.handleClose()
    }

    return <Modal show={props.show} onHide={handleClose} centered size="lg">
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-24 fw-600 border-0' closeButton>
                Add Player
            </Modal.Header>
            <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
                <div className='w-100'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <span className='fs-20 fw-600'>Players</span>
                            <div role='button' className={classNames('bg-danger bg-opacity-10 rounded py-1 px-3', {
                                "d-none": (props?.tournament?.category_type === "INDIVIDUAL" && (allWatchedValues?.event_type === "MEN_SINGLE" || allWatchedValues?.event_type === "WOMEN_SINGLE" || allWatchedValues?.event_type === "JUNIOR_SINGLE")) || (props?.tournament?.category_type === "INDIVIDUAL" && (allWatchedValues?.event_type === "MEN_DOUBLE" || allWatchedValues?.event_type === "WOMEN_DOUBLE" || allWatchedValues?.event_type === "MIX_DOUBLE" || allWatchedValues?.event_type === "JUNIOR_DOUBLE") && fields.length === 2) || (props?.tournament?.category_type === "INDIVIDUAL" && (allWatchedValues?.event_type === "MEN_3_3" || allWatchedValues?.event_type === "WOMEN_3_3" || allWatchedValues?.event_type === "MIX_3_3") && fields?.length === 3) || !allWatchedValues?.event_type
                            })} onClick={() => {
                                append({
                                    player_name: "",
                                    club_name: "",
                                    player_email: "",
                                    player_contact: "",
                                    player_gender: "MALE",
                                    player_dob: "",
                                    player_idcard: "",
                                    nationality: ""
                                })
                            }}>
                                <span className='text-primary fw-bold'>+ Add Player</span>
                            </div>
                        </div>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="my-4">
                                    <Form.Label className='fs-14 fw-500'>Select Event</Form.Label>
                                    <Controller
                                        name={`event_type_age_group_value`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={reactSelectCustomStyle}
                                                placeholder='Select Event'
                                                options={props?.tournament?.event_details?.map((item) => {
                                                    return {
                                                        value: `${item?.event_type}|${item?.age_group}|${item?.age_value}`,
                                                        label: `${convertEventType(item?.event_type)} (${convertAgeGroup(item?.age_group, item?.age_value)})`
                                                    }
                                                })}
                                                isSearchable={true}
                                                {...field}
                                                onChange={(value) => {
                                                    setValue("event_type_age_group_value", value)
                                                    setValue(`event_type`, value?.value?.split("|")[0])
                                                }}
                                            />
                                        )}
                                        rules={{
                                            required: 'Event Type is required'
                                        }}
                                    />
                                    {errors?.event_type_age_group_value && <ErrorText>{errors?.event_type_age_group_value?.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {fields.map((item, index) => {
                            return <div className='mb-3 px-3 py-2 border rounded' key={index}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className='fs-20 fw-600'>Player - {index + 1}</span>
                                    {fields.length > 1 && <IoMdClose role='button' color='red' size={20} onClick={() => remove(index)} />}
                                </div>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="my-2">
                                            <Form.Label className='fs-14 fw-500'>Player Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Player Name"
                                                {...register(`player_details[${index}].player_name`, {
                                                    required: "Player name is required",
                                                })}
                                            />
                                            {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_name?.message}</ErrorText>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="my-2">
                                            <Form.Label className='fs-14 fw-500'>Club Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Club Name"
                                                {...register(`player_details[${index}].club_name`)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="my-2">
                                            <Form.Label className='fs-14 fw-500'>Player Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter email"
                                                {...register(`player_details[${index}].player_email`, {
                                                    required: "Player Email is required",
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "Invalid email address"
                                                    }
                                                })}
                                            />
                                            {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_email?.message}</ErrorText>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="my-2">
                                            <Form.Label className='fs-14 fw-500'>Player Contact Number</Form.Label>
                                            <Form.Control
                                                type="tel"
                                                placeholder="Enter Player Contact Number"
                                                {...register(`player_details[${index}].player_contact`, {
                                                    required: "Player Contact Number is required",
                                                    minLength: {
                                                        value: 9,
                                                        message: "Contact Number should be 9 digit minimum"
                                                    },
                                                    maxLength: {
                                                        value: 11,
                                                        message: "Contact Number should be 11 digit maximum"
                                                    }
                                                })}
                                            />
                                            {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_contact?.message}</ErrorText>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="my-2">
                                            <Form.Label className='fs-14 fw-500'>Player Gender</Form.Label>
                                            <Form.Select
                                                {...register(`player_details[${index}].player_gender`, {
                                                    required: "Player Gender is required"
                                                })}
                                            >
                                                <option value='MALE'>Male</option>
                                                <option value='FEMALE'>Female</option>
                                            </Form.Select>
                                            {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_gender?.message}</ErrorText>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="d-flex flex-column align-items-start my-2">
                                            <Form.Label className='text-fs-14 fw-500'>Date of Birth</Form.Label>
                                            <Controller
                                                control={control}
                                                name={`player_details[${index}].player_dob`}
                                                render={({ field }) => (
                                                    <DatePickerWithYearAndMonth
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={(date) => field.onChange(date)}
                                                        maxDate={new Date()}
                                                    />
                                                )}
                                                rules={{ required: 'Date of Birth is required' }}
                                            />
                                            {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_dob?.message}</ErrorText>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="my-2">
                                            <Form.Label className='fs-14 fw-500'>Player IC/Passport Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Player IC/Passport Number"
                                                {...register(`player_details[${index}].player_idcard`, {
                                                    required: "Player IC/Passport Number is required",
                                                })}
                                            />
                                            {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_idcard?.message}</ErrorText>}
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="my-2">
                                            <Form.Label className='fs-14 fw-500'>Country</Form.Label>
                                            <Form.Select
                                                {...register(`player_details[${index}].nationality`, {
                                                    required: "Player Country is required",
                                                })}
                                            >
                                                <option value="">Select player country</option>
                                                {countryList?.map((item, index) => {
                                                    return <option value={item} key={index}>{item}</option>
                                                })}
                                            </Form.Select>
                                            {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.nationality?.message}</ErrorText>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Check
                                    className='mb-2'
                                    type='checkbox'
                                    label={<span role='button' className='fs-14 fw-600 text-secondary'>
                                        Are you a State/District/National Player?
                                    </span>}
                                    id={`proffesional_player${index}`}
                                    {...register(`player_details[${index}].proffesional_player`)}
                                />
                                <Form.Check
                                    type='checkbox'
                                    label={<span role='button' className='fs-14 fw-600 text-secondary'>
                                        Declare that the information shared are true and confirm.
                                    </span>}
                                    id={`declare${index}`}
                                    required
                                />
                            </div>
                        })}
                        <div className='d-flex justify-content-end align-items-end mt-4'>
                            <div>
                                <PrimaryButton className='fs-14 fw-500 px-5' disabled={loading} loading={loading} type="submit">Add Player</PrimaryButton>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default AddPlayerModal;