import { Helmet, HelmetProvider } from 'react-helmet-async';

const SSO = () => {
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>SSO | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
    </HelmetProvider>
};

export default SSO;