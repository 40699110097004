import { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BsArrowRight } from "react-icons/bs";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import classNames from 'classnames';

// Custom Component
import ErrorAlert from '../Alert/ErrorAlert';

// Images
import { Live } from "../../assets/images/svg";

// API Service
import { allCustomerBannerService, allOrganizerBannerService } from '../../service/banner.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

const BannerSection = () => {
    const [bannerList, setBannerList] = useState([])

    const user = useSelector(selectUser);

    /**
        * @function fetchCustomerBannerList
        * @params
        * @description fetch the list of customer banner
    */
    const fetchCustomerBannerList = async () => {
        try {
            const result = await allCustomerBannerService();
            if (result?.data?.status) {
                setBannerList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchOrganizerBannerList
        * @params
        * @description fetch the list of organizer banner
    */
    const fetchOrganizerBannerList = async () => {
        try {
            const result = await allOrganizerBannerService();
            if (result?.data?.status) {
                setBannerList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerBannerList()
        } else {
            fetchCustomerBannerList();
        }
    }, [user])

    return <Carousel className='home-carousel'
        nextIcon={<MdArrowForwardIos className={classNames({ 'd-none': bannerList?.length === 1 })} size={30} />}
        prevIcon={<MdArrowBackIosNew className={classNames({ 'd-none': bannerList?.length === 1 })} size={30} />}
    >
        {bannerList?.length > 0 && bannerList?.map((item, index) => {
            return <Carousel.Item key={index}>
                <img
                    className="d-block w-100 carousel-image rounded-lg-5"
                    src={item?.image}
                    alt={item?.title}
                />
                <Carousel.Caption className='w-100 px-4'>
                    <div className='d-flex flex-wrap justify-content-between align-items-center'>
                        <div className='d-flex align-items-center mt-3'>
                            <Live className='me-2' />
                            <span className='d-none d-lg-block'>{item?.title}</span>
                            <span className='fs-14 d-lg-none'>{item?.title}</span>
                        </div>
                        <a target='_blank' rel='noreferrer' href={item?.url} className='text-decoration-none d-none d-lg-block z-2'>
                            <div className='d-flex align-items-center text-primary bg-light px-4 py-1 rounded mt-3'>
                                <span className='me-2'>View</span>
                                <BsArrowRight size={20} />
                            </div>
                        </a>
                    </div>
                    <div className='d-flex flex-wrap justify-content-end align-items-center'>
                        <a target='_blank' rel='noreferrer' href={item?.url} className='text-decoration-none d-lg-none z-2'>
                            <div className='d-flex align-items-center text-primary bg-light px-3 py-1 rounded'>
                                <span className='fs-12 me-2'>View</span>
                                <BsArrowRight size={12} />
                            </div>
                        </a>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        })}
    </Carousel>
};

export default BannerSection;