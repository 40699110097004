import { useState, useEffect, useRef } from 'react';
import { Modal, Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import { ImageDrop } from 'quill-image-drop-module';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import TimePicker from 'react-time-picker';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { AiFillCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import classNames from "classnames";
import moment from 'moment';

// Custom Component
import ErrorText from '../../TextField/ErrorText'
import { PrimaryButton, PrimaryOutlineButton } from '../../Button';
import AgeGroup from './AgeGroup';
import CustomDatePicker from '../../CustomDatePicker/CustomDatePicker'
import DatePickerWithYearAndMonth from '../../DatePickerWithYearAndMonth/DatePickerWithYearAndMonth';
import SuccessAlert from '../../Alert/SuccessAlert';
import ErrorAlert from '../../Alert/ErrorAlert';
import ConfirmAlert from "../../Alert/ConfirmAlert";

// Images
// import { Image, User, Users, Men, Women } from '../../../assets/images/svg';
import { Image, Badminton, PickleBall, User, Men, Women } from '../../../assets/images/svg';

// API Service
import { addTournamentService } from '../../../service/tournament.service';
import { allSubOrganizerService } from '../../../service/subOrganizer.service';

// Redux-Functions
import { selectUser } from "../../../redux/userSlice";

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

const CreateTournamentModal = (props) => {
    const user = useSelector(selectUser);

    const [tournamentImage, setTournamentImage] = useState(null);
    const [subOrganizerList, setSubOrganizerList] = useState([])
    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined

    const registrationStartDateRef = useRef(null);
    const registrationEndDateRef = useRef(null);
    const tournamentStartDateRef = useRef(null);
    const tournamentEndDateRef = useRef(null);

    const modules = {
        toolbar: [
            [
                'bold',
                'italic',
                'underline',
                'strike'
            ],
            [
                'blockquote',
                'code-block'
            ],
            [
                {
                    header: [
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        false
                    ]
                }
            ],
            [
                {
                    list: 'ordered'
                },
                {
                    list: 'bullet'
                }
            ],
            [
                {
                    script: 'sub'
                },
                {
                    script: 'super'
                }
            ],
            [
                {
                    indent: '-1'
                },
                {
                    indent: '+1'
                }
            ],
            [
                {
                    direction: 'rtl'
                }
            ],
            [
                {
                    color: []
                },
                {
                    background: []
                }
            ],
            [
                'link',
                'image',
                'video'
            ],
            [
                'clean'
            ]
        ],
        clipboard: {
            matchVisual: false
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: [
                'Resize',
                'DisplaySize',
                'Toolbar'
            ]
        },
        imageDrop: true,
        imageDropAndPaste: true
    };

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'color',
        'align',
        'background',
        'direction',
        'code-block',
        'code'
    ];

    const eventTypeList = ['MEN_SINGLE', 'WOMEN_SINGLE', 'MEN_DOUBLE', 'WOMEN_DOUBLE', 'MEN_3_3', 'WOMEN_3_3', 'MIX_DOUBLE', 'MIX_3_3', 'JUNIOR_SINGLE', 'JUNIOR_DOUBLE']

    const { register, control, watch, setValue, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            event_details: [
                {
                    event_type: "MEN_SINGLE",
                    age_groups: [
                        {
                            age_group: "BELOW_AGE",
                            age_value: "",
                            price: "",
                            number_of_males: "",
                            number_of_females: ""
                        }
                    ]
                }
            ],
            category_type: "INDIVIDUAL"
        },
        mode: "onBlur"
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "event_details"
    });

    const watchSportsType = watch("sports_type")
    const watchCategoryType = watch("category_type")
    const watchNumberOfIndividualEvent = watch("number_of_individual_event")
    const allWatchedValues = watch({
        nest: true
    });

    /**
        * @function fetchSubOrganizerList
        * @params 
        * @description fetch the list of sub organizer
    */
    const fetchSubOrganizerList = async () => {
        try {
            const result = await allSubOrganizerService({
                paginate: 0,
                page: '',
                perPage: '',
                keyword: '',
                status: 1
            });
            if (result?.data?.status) {
                setSubOrganizerList(result?.data?.data?.organizations)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchSubOrganizerList()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (watchNumberOfIndividualEvent) {
            if (parseInt(watchNumberOfIndividualEvent) > allWatchedValues?.event_details?.length) {
                const addEvent = Array(parseInt(watchNumberOfIndividualEvent) - allWatchedValues?.event_details?.length).fill({
                    event_type: eventTypeList.filter(x => !allWatchedValues?.event_details?.map(item => item?.event_type).includes(x))[0],
                    age_groups: [
                        {
                            age_group: "BELOW_AGE",
                            age_value: "",
                            price: "",
                            number_of_males: "",
                            number_of_females: ""
                        }
                    ]
                })
                if (addEvent?.length + allWatchedValues?.event_details?.length >= 8) {
                    ErrorAlert("You can't add more than 8 event")
                } else {
                    append(addEvent)
                }
            } else if (parseInt(watchNumberOfIndividualEvent) < allWatchedValues?.event_details?.length) {
                setValue('event_details', allWatchedValues?.event_details.slice(0, watchNumberOfIndividualEvent));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchNumberOfIndividualEvent])

    /**
        * @function onSubmit
        * @params formdata
        * @description used to create tournament
    */
    const onSubmit = async (formdata) => {
        try {
            if (!tournamentImage) {
                ErrorAlert('You need to upload a tournament image')
                return
            }
            const data = new FormData()
            if (formdata?.organization_id) {
                formdata?.organization_id?.forEach((item, index) => {
                    data.append(`organization_ids[${index}]`, item?.value)
                })
            }
            data.append('name', formdata?.name)
            data.append('description', formdata?.description)
            data.append('prizes', formdata?.prizes)
            data.append('terms_and_condition', formdata?.terms_and_condition)
            data.append('banner_image', tournamentImage)
            data.append('sports_type', formdata?.sports_type)
            data.append('category_type', formdata?.category_type)
            data.append('registration_start_date', moment(formdata?.registration_start_date).format("YYYY-MM-DD"))
            data.append('registration_end_date', moment(formdata?.registration_end_date).format("YYYY-MM-DD"))
            data.append('tournament_start_date', moment(formdata?.tournament_start_date).format("YYYY-MM-DD"))
            data.append('tournament_end_date', moment(formdata?.tournament_end_date).format("YYYY-MM-DD"))
            data.append('tournament_start_time', moment(formdata?.tournament_start_time, "hh:mm A").format("HH:mm"))
            data.append('tournament_end_time', moment(formdata?.tournament_end_time, "hh:mm A").format("HH:mm"))
            if (formdata?.event_details?.length > 0) {
                formdata?.event_details?.forEach((item, index) => {
                    if (item?.event_type) {
                        data.append(`event_details[${index}][event_type]`, item?.event_type)
                    }
                    if (item?.age_groups?.length > 0) {
                        item?.age_groups?.forEach((nestedItem, nestedIndex) => {
                            if (nestedItem?.age_group) {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][age_group]`, nestedItem?.age_group)
                            }
                            if (nestedItem?.age_group !== "OPEN" && nestedItem?.age_group !== "AMATEUR" && nestedItem?.age_group !== "BAKAT_BARU" && nestedItem?.age_group !== "SEMI_PRO" && nestedItem?.age_value) {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][age_value]`, nestedItem?.age_value)
                            }
                            if (watchCategoryType === "INDIVIDUAL" && nestedItem?.price) {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][price]`, nestedItem?.price)
                            }
                            if (item?.event_type === 'MEN_SINGLE' && watchCategoryType === "TEAM") {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_males]`, 1)
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_females]`, 0)
                            }
                            if (item?.event_type === 'WOMEN_SINGLE' && watchCategoryType === "TEAM") {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_males]`, 0)
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_females]`, 1)
                            }
                            if (item?.event_type === 'MEN_DOUBLE') {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_males]`, 2)
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_females]`, 0)
                            }
                            if (item?.event_type === 'WOMEN_DOUBLE') {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_males]`, 0)
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_females]`, 2)
                            }
                            if (item?.event_type === 'MIX_DOUBLE') {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_males]`, 1)
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_females]`, 1)
                            }
                            if (item?.event_type === 'MEN_3_3') {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_males]`, 3)
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_females]`, 0)
                            }
                            if (item?.event_type === 'WOMEN_3_3') {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_males]`, 0)
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_females]`, 3)
                            }
                            if (nestedItem?.number_of_males && nestedItem?.number_of_females && (item?.event_type === 'MIX_3_3') && (parseInt(nestedItem?.number_of_males) + parseInt(nestedItem?.number_of_females)) !== 3) {
                                ErrorAlert('You need to choose maximum 3 person for Mixed 3 on 3 event')
                                return
                            }
                            if (nestedItem?.number_of_males && (item?.event_type === 'MIX_3_3')) {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_males]`, nestedItem?.number_of_males)
                            }
                            if (nestedItem?.number_of_females && (item?.event_type === 'MIX_3_3')) {
                                data.append(`event_details[${index}][age_groups][${nestedIndex}][number_of_females]`, nestedItem?.number_of_females)
                            }
                            data.append(`event_details[${index}][age_groups][${nestedIndex}][allow_proffesional_players]`, nestedItem?.allow_proffesional_players ? 1 : 0)
                        })
                    }
                })
            }
            data.append('contact', formdata?.contact)
            data.append('phone_number', formdata?.phone_number)
            data.append('address', formdata?.address)
            if (watchCategoryType === "TEAM") {
                data.append('team_event_price', parseInt(formdata?.team_event_price))
                data.append('number_of_individual_event', parseInt(formdata?.number_of_individual_event))
            }
            setLoading(true)
            const result = await addTournamentService(data);
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                props?.fetchTournamentList(1)
                handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
        * @function handleClose
        * @params
        * @description used to close create tournament modal
    */
    const handleClose = async () => {
        reset()
        setTournamentImage()
        props.handleClose()
    }

    const reactSelectCustomStyle = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: state.isFocused && "1px solid #FA5000",
            boxShadow: state.isFocused && "0px 0px 2px #FA5000",
            "&:hover": {
                border: "1px solid #FA5000",
                boxShadow: "0px 0px 2px #FA5000"
            }
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#FA5000' : 'white',
                "&:hover": {
                    backgroundColor: "#FA5000",
                    color: 'white'
                }
            }
        }
    }

    /**
        * @function SelectMenuButton
        * @params props
        * @description used for add button in select dropdown
    */
    const SelectMenuButton = (props) => {
        return <components.MenuList {...props}>
            {props.children}
            <div className='py-3 px-1'>
                <Link to='/sub-organizer' className='text-decoration-none'>
                    <div className='d-inline-block'>
                        <PrimaryOutlineButton className='rounded-pill px-2 py-1'>+ Sub Organizer</PrimaryOutlineButton>
                    </div>
                </Link>
            </div>
        </components.MenuList>
    }

    return <Modal show={props.show} onHide={() => ConfirmAlert('Are you sure on closing this?', "You will loose all progress.", handleClose)} size={props.size} centered backdrop="static" keyboard={false}>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-22 fw-bold border-0' closeButton >
                Create Tournament
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center'>
                <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <input className='d-none' type='file' id="tournamentPhotoUpload" accept="image/*" onChange={(e) => {
                        if (e.target.files.length > 0) {
                            const file = e.target.files[0];
                            const img = document.createElement("img");
                            img.onload = function () {
                                if (img.width <= 960 && (img.width / img.height)?.toFixed(2) === (23 / 9)?.toFixed(2)) {
                                    setTournamentImage(file)
                                } else {
                                    ErrorAlert("Please upload the correct ratio tournament image. Ratio will be 23/9")
                                }
                            };
                            img.src = URL.createObjectURL(file);
                        }
                    }} onClick={(e) => e.target.value = null} />
                    <label className='w-100' htmlFor="tournamentPhotoUpload">
                        {tournamentImage
                            ?
                            <img role='button' className='w-100 img-fluid' src={URL.createObjectURL(tournamentImage)} alt={'tournamentImage'} />
                            :
                            <div role='button' className='d-flex flex-column justify-content-center align-items-center bg-danger bg-opacity-10 py-5'>
                                <Image className='mb-3' />
                                <span className='fs-18 fw-600 text-primary mb-3'>Add Banners here</span>
                                <span className='fw-400 text-secondary'>Maximum width 960 pixels, 23:9 recommended</span>
                            </div>
                        }
                    </label>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Select Sub Organizer</Form.Label>
                        <Controller
                            name={`organization_id`}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    styles={reactSelectCustomStyle}
                                    placeholder='Select Sub Organizer'
                                    components={{ MenuList: SelectMenuButton }}
                                    options={subOrganizerList?.map((item) => {
                                        return {
                                            value: item?.id,
                                            label: item?.name
                                        }
                                    })}
                                    isMulti={true}
                                    isSearchable={true}
                                    {...field}
                                />
                            )}
                        />
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Tournament name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name here"
                            {...register("name", {
                                required: "Tournament name is required"
                            })}
                        />
                        {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Description</Form.Label>
                        <Controller
                            id='description'
                            name={`description`}
                            control={control}
                            render={({ field }) => (
                                <ReactQuill
                                    theme="snow"
                                    value={field.value}
                                    modules={modules}
                                    formats={formats}
                                    onChange={field.onChange}
                                    preserveWhitespace={true}
                                />
                            )}
                            rules={{
                                required: "Description is required"
                            }}
                        />
                        {errors.description && <ErrorText>{errors.description.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Prizes</Form.Label>
                        <Controller
                            id='prizes'
                            name={`prizes`}
                            control={control}
                            render={({ field }) => (
                                <ReactQuill
                                    theme="snow"
                                    value={field.value}
                                    modules={modules}
                                    formats={formats}
                                    onChange={field.onChange}
                                    preserveWhitespace={true}
                                />
                            )}
                            rules={{
                                required: "Prizes is required"
                            }}
                        />
                        {errors.prizes && <ErrorText>{errors.prizes.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className='fs-14 fw-500'>Terms & Conditions</Form.Label>
                        <Controller
                            id='terms_and_condition'
                            name={`terms_and_condition`}
                            control={control}
                            render={({ field }) => (
                                <ReactQuill
                                    theme="snow"
                                    value={field.value}
                                    modules={modules}
                                    formats={formats}
                                    onChange={field.onChange}
                                    preserveWhitespace={true}
                                />
                            )}
                            rules={{
                                required: "Terms & Conditions is required"
                            }}
                        />
                        {errors.terms_and_condition && <ErrorText>{errors.terms_and_condition.message}</ErrorText>}
                    </Form.Group>

                    <Row className='mb-4'>
                        <span className='fs-18 fw-600 mb-3'>Contact</span>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label className='fs-14 fw-500'>Contact Person</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter name here"
                                    {...register("contact", {
                                        required: "Contact Person is required",
                                    })}
                                />
                                {errors.contact && <ErrorText>{errors.contact.message}</ErrorText>}
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label className='fs-14 fw-500'>Phone number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="Enter phone number here"
                                    {...register("phone_number", {
                                        required: "Phone number is required",
                                        minLength: {
                                            value: 9,
                                            message: "Phone Number should be 9 digit minimum"
                                        },
                                        maxLength: {
                                            value: 11,
                                            message: "Phone Number should be 11 digit maximum"
                                        }
                                    })}
                                />
                                {errors.phone_number && <ErrorText>{errors.phone_number.message}</ErrorText>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Location</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter location here"
                            {...register("address", {
                                required: "Location is required",
                            })}
                        />
                        {errors.address && <ErrorText>{errors.address.message}</ErrorText>}
                    </Form.Group>

                    <div className='d-flex flex-column align-items-start mb-4'>
                        <span className='fs-18 fw-600'>Type of Sports</span>
                        <div className='d-flex flex-wrap align-items-start'>
                            <Form.Check
                                className='sport-type ps-0'
                                type='radio'
                                label={<div role="button" className={classNames('d-flex flex-column align-items-center rounded py-2 px-3 mt-3 me-3', {
                                    "text-light bg-primary": watchSportsType === "BADMINTON",
                                    "text-primary": watchSportsType !== "BADMINTON",
                                })}>
                                    <Badminton className='img-fluid mt-2 mb-2' />
                                    <span>Badminton</span>
                                </div>}
                                id={`badminton`}
                                value="BADMINTON"
                                {...register("sports_type")}
                            />
                            <Form.Check
                                className='sport-type ps-0'
                                type='radio'
                                label={<div role="button" className={classNames('d-flex flex-column align-items-center rounded py-2 px-3 mt-3', {
                                    "text-light bg-primary": watchSportsType === "PICKLEBALL",
                                    "text-primary": watchSportsType !== "PICKLEBALL",
                                })}>
                                    <PickleBall className='img-fluid mt-2 mb-2' />
                                    <span>Pickleball</span>
                                </div>}
                                id={`pickleball`}
                                value="PICKLEBALL"
                                {...register("sports_type")}
                            />
                        </div>
                    </div>

                    <Row className='mb-4'>
                        <span className='fs-18 fw-600 mb-3'>Categories</span>
                        <Col xs={6}>
                            <Form.Check
                                className='tournament-category ps-0'
                                type='radio'
                                label={<span role='button'>
                                    {watchCategoryType === "INDIVIDUAL" && <AiFillCheckCircle className='text-primary me-3' size={25} />}
                                    <User className='me-2' color={watchCategoryType === "INDIVIDUAL" && '#FA5000'} />
                                    Individuals Event
                                </span>}
                                id={`individuals-event`}
                                value="INDIVIDUAL"
                                {...register("category_type")}
                            />
                        </Col>
                        {/* <Col xs={6}>
                            <Form.Check
                                className='tournament-category ps-0'
                                type='radio'
                                label={<span role='button'>
                                    {watchCategoryType === "TEAM" && <AiFillCheckCircle className='text-primary me-3' size={25} />}
                                    <Users className='me-2' color={watchCategoryType === "TEAM" && '#FA5000'} />
                                    Team Event
                                </span>}
                                id={`team-event`}
                                value="TEAM"
                                {...register("category_type")}
                            />
                        </Col> */}
                    </Row>

                    {watchCategoryType === "TEAM" &&
                        <Row className='mb-4'>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label className='fs-14 fw-500'>No of Individual Event in a Team Event</Form.Label>
                                    <Form.Control
                                        type="number"
                                        {...register("number_of_individual_event", {
                                            required: "No of Individual Event is required",
                                            max: {
                                                value: 8,
                                                message: "You can't add more than 8 event"
                                            }
                                        })}
                                    />
                                    {errors.number_of_individual_event && <ErrorText>{errors.number_of_individual_event.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label className='fs-14 fw-500'>Price for each team</Form.Label>
                                    <InputGroup className='rounded'>
                                        <InputGroup.Text className='bg-transparent border-end-0'>RM</InputGroup.Text>
                                        <Form.Control
                                            className='border-start-0'
                                            type="number"
                                            {...register("team_event_price", {
                                                required: "Price for each team is required",
                                            })}
                                        />
                                    </InputGroup>
                                    {errors.team_event_price && <ErrorText>{errors.team_event_price.message}</ErrorText>}
                                </Form.Group>
                            </Col>
                        </Row>
                    }

                    <span className='fw-600'>Individual Event</span>

                    {fields.map((item, index) => {
                        return (
                            <div className='mt-3 p-3 border bg-gray rounded' key={item.id}>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <span className='fw-600 text-dark'>{index + 1}. Type of Individual Event</span>
                                    {fields.length > 1 && <IoMdClose role='button' color='red' size={20} onClick={() => {
                                        Swal.fire({
                                            title: "Are you sure ?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            showCloseButton: true,
                                            confirmButtonColor: "#FA5000",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Yes, Delete It!",
                                            reverseButtons: true,
                                            customClass: {
                                                container: 'event-delete-swal-container',
                                                title: 'fs-20 mb-3'
                                            }
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                remove(index)
                                            }
                                        });
                                    }} />}
                                </div>
                                <div className='d-flex flex-wrap align-items-center'>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "MEN_SINGLE", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "MEN_SINGLE" })}>
                                                <Men className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "MEN_SINGLE" && 'white'} />
                                                Men Single
                                            </span>}
                                            id={`men-single${index}`}
                                            value="MEN_SINGLE"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "MEN_SINGLE" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "WOMEN_SINGLE", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "WOMEN_SINGLE" })}>
                                                <Women className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "WOMEN_SINGLE" && 'white'} />
                                                Women Single
                                            </span>}
                                            id={`women-single${index}`}
                                            value="WOMEN_SINGLE"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "WOMEN_SINGLE" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "MEN_DOUBLE", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "MEN_DOUBLE" })}>
                                                <Men className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "MEN_DOUBLE" && 'white'} />
                                                Men Double
                                            </span>}
                                            id={`men-double${index}`}
                                            value="MEN_DOUBLE"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "MEN_DOUBLE" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "WOMEN_DOUBLE", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "WOMEN_DOUBLE" })}>
                                                <Women className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "WOMEN_DOUBLE" && 'white'} />
                                                Women Double
                                            </span>}
                                            id={`women-double${index}`}
                                            value="WOMEN_DOUBLE"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "WOMEN_DOUBLE" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "MIX_DOUBLE", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "MIX_DOUBLE" })}>
                                                <Men className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "MIX_DOUBLE" && 'white'} />
                                                <Women className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "MIX_DOUBLE" && 'white'} />
                                                Mix Double
                                            </span>}
                                            id={`mix-double${index}`}
                                            value="MIX_DOUBLE"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "MIX_DOUBLE" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "MEN_3_3", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "MEN_3_3" })}>
                                                <Men className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "MEN_3_3" && 'white'} />
                                                Men 3 on 3
                                            </span>}
                                            id={`men-3-on-3${index}`}
                                            value="MEN_3_3"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "MEN_3_3" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "WOMEN_3_3", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "WOMEN_3_3" })}>
                                                <Women className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "WOMEN_3_3" && 'white'} />
                                                Women 3 on 3
                                            </span>}
                                            id={`women-3-on-3${index}`}
                                            value="WOMEN_3_3"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "WOMEN_3_3" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "MIX_3_3", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "MIX_3_3" })}>
                                                <Men className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "MIX_3_3" && 'white'} />
                                                <Women className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "MIX_3_3" && 'white'} />
                                                Mix 3 on 3
                                            </span>}
                                            id={`mix-3-on-3${index}`}
                                            value="MIX_3_3"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "MIX_3_3" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "JUNIOR_SINGLE", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "JUNIOR_SINGLE" })}>
                                                <Men className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "JUNIOR_SINGLE" && 'white'} />
                                                <Women className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "JUNIOR_SINGLE" && 'white'} />
                                                Junior Single
                                            </span>}
                                            id={`junior-single${index}`}
                                            value="JUNIOR_SINGLE"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "JUNIOR_SINGLE" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check
                                            className='event-type ps-0 mt-3 me-2'
                                            type='radio'
                                            label={<span role='button' className={classNames('p-2', { 'bg-primary text-light rounded': allWatchedValues?.event_details[index]?.event_type === "JUNIOR_DOUBLE", 'bg-event rounded': allWatchedValues?.event_details[index]?.event_type !== "JUNIOR_DOUBLE" })}>
                                                <Men className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "JUNIOR_DOUBLE" && 'white'} />
                                                <Women className='me-2' color={allWatchedValues?.event_details[index]?.event_type === "JUNIOR_DOUBLE" && 'white'} />
                                                Junior Double
                                            </span>}
                                            id={`junior-double${index}`}
                                            value="JUNIOR_DOUBLE"
                                            {...register(`event_details[${index}].event_type`)}
                                            disabled={allWatchedValues?.event_details?.find((eventItem, eventIndex) => eventItem.event_type === "JUNIOR_DOUBLE" && index !== eventIndex)}
                                        />
                                    </Form.Group>
                                </div>
                                <AgeGroup nestIndex={index} allWatchedValues={allWatchedValues} {...{ control, register, errors }} />
                            </div>
                        );
                    })}

                    <div role='button' className='d-flex justify-content-center align-items-center mt-3 border rounded py-2' onClick={() => {
                        if (allWatchedValues?.event_details?.length >= 8) {
                            ErrorAlert("You can't add more than 8 event")
                        } else {
                            append({
                                event_type: eventTypeList.filter(x => !allWatchedValues?.event_details?.map(item => item?.event_type).includes(x))[0],
                                age_groups: [
                                    {
                                        age_group: "BELOW_AGE",
                                        age_value: "",
                                        price: "",
                                        number_of_males: "",
                                        number_of_females: ""
                                    }
                                ]
                            })
                        }
                    }}>
                        <AiOutlinePlus className='me-2' size={25} />
                        <span className='fs-14 fw-600 text-secondary'>Add more individual Event</span>
                    </div>

                    <Row className='my-4'>
                        <span className='fs-18 fw-600 mb-3'>Tournament registration Time</span>
                        <Col xs={6}>
                            <Form.Group className="d-flex flex-column align-items-start">
                                <Form.Label className='text-fs-14 fw-500'>Start date</Form.Label>
                                <Controller
                                    control={control}
                                    name={`registration_start_date`}
                                    render={({ field }) => (
                                        <DatePickerWithYearAndMonth
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            customInput={<CustomDatePicker
                                                {...field}
                                                inputRef={registrationStartDateRef}
                                            />}
                                        />
                                    )}
                                    rules={{ required: 'Registration Start date is required' }}
                                />
                                {errors.registration_start_date && <ErrorText>{errors.registration_start_date.message}</ErrorText>}
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group className="d-flex flex-column align-items-start">
                                <Form.Label className='text-fs-14 fw-500'>End date</Form.Label>
                                <Controller
                                    control={control}
                                    name={`registration_end_date`}
                                    render={({ field }) => (
                                        <DatePickerWithYearAndMonth
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            customInput={<CustomDatePicker
                                                {...field}
                                                inputRef={registrationEndDateRef}
                                            />}
                                        />
                                    )}
                                    rules={{ required: 'Registration End date is required' }}
                                />
                                {errors.registration_end_date && <ErrorText>{errors.registration_end_date.message}</ErrorText>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <span className='fs-18 fw-600 mb-3'>Tournament Time</span>
                        <Col xs={6} lg={3}>
                            <Form.Group className="d-flex flex-column align-items-start">
                                <Form.Label className='text-fs-14 fw-500'>Start date</Form.Label>
                                <Controller
                                    control={control}
                                    name={`tournament_start_date`}
                                    render={({ field }) => (
                                        <DatePickerWithYearAndMonth
                                            {...field} v
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            customInput={<CustomDatePicker
                                                {...field}
                                                inputRef={tournamentStartDateRef}
                                            />}
                                        />
                                    )}
                                    rules={{ required: 'Tournament Start date is required' }}
                                />
                                {errors.tournament_start_date && <ErrorText>{errors.tournament_start_date.message}</ErrorText>}
                            </Form.Group>
                        </Col>
                        <Col xs={6} lg={3}>
                            <Form.Group>
                                <Form.Label className='text-fs-14 fw-500'>Start time</Form.Label>
                                <Controller
                                    control={control}
                                    name={`tournament_start_time`}
                                    render={({ field }) => (
                                        <TimePicker
                                            className='form-control'
                                            onChange={field.onChange}
                                            value={field.value}
                                            minutePlaceholder='mm'
                                            hourPlaceholder='hh'
                                            format="hh:mm a"
                                            openClockOnFocus={false}
                                            closeClock={true}
                                            disableClock={true}
                                        />
                                    )}
                                    rules={{ required: 'Start time is required' }}
                                />
                                {errors.tournament_start_time && <ErrorText>{errors.tournament_start_time?.message}</ErrorText>}
                            </Form.Group>
                        </Col>
                        <Col xs={6} lg={3} className='mt-3 mt-lg-0'>
                            <Form.Group className="d-flex flex-column align-items-start">
                                <Form.Label className='text-fs-14 fw-500'>End date</Form.Label>
                                <Controller
                                    control={control}
                                    name={`tournament_end_date`}
                                    render={({ field }) => (
                                        <DatePickerWithYearAndMonth
                                            {...field}
                                            selected={field.value}
                                            onChange={(date) => field.onChange(date)}
                                            customInput={<CustomDatePicker
                                                {...field}
                                                inputRef={tournamentEndDateRef}
                                            />}
                                        />
                                    )}
                                    rules={{ required: 'Tournament End date is required' }}
                                />
                                {errors.tournament_end_date && <ErrorText>{errors.tournament_end_date.message}</ErrorText>}
                            </Form.Group>
                        </Col>
                        <Col xs={6} lg={3} className='mt-3 mt-lg-0'>
                            <Form.Group>
                                <Form.Label className='text-fs-14 fw-500'>End time</Form.Label>
                                <Controller
                                    control={control}
                                    name={`tournament_end_time`}
                                    render={({ field }) => (
                                        <TimePicker
                                            className='form-control'
                                            onChange={field.onChange}
                                            value={field.value}
                                            minutePlaceholder='mm'
                                            hourPlaceholder='hh'
                                            format="hh:mm a"
                                            openClockOnFocus={false}
                                            closeClock={true}
                                            disableClock={true}
                                        />
                                    )}
                                    rules={{ required: 'End time is required' }}
                                />
                                {errors.tournament_end_time && <ErrorText>{errors.tournament_end_time?.message}</ErrorText>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className='d-flex justify-content-end align-items-end mt-4'>
                        <div>
                            <PrimaryButton className='fs-14 fw-500 px-5' disabled={loading} loading={loading} type="submit">Create</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default CreateTournamentModal;