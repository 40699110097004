import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { IoMdClose } from "react-icons/io";

// Custom Components
import { PrimaryButton, DangerButton } from '../Button';

const MySwal = withReactContent(Swal)

const ConfirmAlert = (message, staticMessage, buttonFunction) => {
    MySwal.fire({
        html: (
            <div className="d-flex flex-column align-items-center border border-primary rounded py-3">
                <div className='d-flex flex-column align-items-center position-relative w-100'>
                    <span className='fs-20 fw-600 mt-4'>{message || 'Are you sure you want to do this'}</span>
                    <span className="fs-20 fw-600">{staticMessage || "You won't be able to revert this!"}</span>
                    <IoMdClose role='button' className='text-danger position-absolute top-0 end-0 me-2' size={20} onClick={() => {
                        MySwal.close();
                    }} />
                </div>
                <div className="d-flex justify-content-center align-items-center w-100 mt-2">
						<div className='me-2'>
                        <DangerButton className='px-sm-4' onClick={() => {
                            buttonFunction()
                            MySwal.close();
                        }}>Yes</DangerButton>
						</div>
						<div>
							<PrimaryButton className='px-sm-4' onClick={() => {
								MySwal.close();
							}}>Cancel</PrimaryButton>
						</div>
					</div>
            </div>
        )
    });
};

export default ConfirmAlert;