import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import moment from 'moment';

const DatePickerWithYearAndMonth = (props) => {
    /**
        * @function range
        * @params start, stop, step
        * @description for getting the range of arrays in specific range with step
    */
    const range = (start, stop, step) => {
        if (typeof stop === 'undefined') {
            stop = start;
            start = 0;
        }

        if (typeof step === 'undefined') {
            step = 1;
        }

        if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
            return [];
        }

        var result = [];
        for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
            result.push(i);
        }

        return result;
    };

    const yearsRange = range(moment().year() - 100, moment().year() + 1, 1);

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const [years, setYears] = useState(yearsRange);

    /**
        * @function addYearToList
        * @params newYear
        * @description used to add year in dropdown
    */
    const addYearToList = (newYear) => {
        if (!years.includes(newYear)) {
            setYears((prevYears) => [...prevYears, newYear]);
        }
    };

    useEffect(() => {
        const selectedYear = moment(props.selected).year();
        addYearToList(selectedYear);
        // eslint-disable-next-line
    }, [props.selected]);

    const currentDate = moment();

    return <DatePicker
        {...props}
        renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth
        }) => {
            const selectedMonth = moment(date).month();
            const selectedYear = moment(date).year();

            const disableDecreaseMonth = (selectedYear === currentDate.year() - 100 && selectedMonth === 0);
            const disableIncreaseMonth = props.maxDate && moment(date).isSame(props.maxDate, 'month') && selectedYear === currentDate.year();

            /**
                * @function handleMonthChange
                * @params month
                * @description used to handle month changes and add the year to list if needed
            */
            const handleMonthChange = (month) => {
                const newDate = moment(date).add(month, 'months');
                const newYear = newDate.year();

                if (newYear !== selectedYear) {
                    addYearToList(newYear);
                }
            };

            return <>
                <div className="d-flex justify-content-between align-items-center fs-16 fw-bold m-3">
                    <BsFillArrowLeftCircleFill
                        role="button"
                        size={20}
                        className={`me-3 text-primary ${disableDecreaseMonth ? 'pe-none opacity-50' : ''}`}
                        onClick={() => {
                            handleMonthChange(-1);
                            decreaseMonth();
                        }}
                        disabled={disableDecreaseMonth}
                    />
                    <div className="d-flex align-items-center">
                        <span className="me-1">{months[selectedMonth]}</span>
                        <span>{selectedYear}</span>
                    </div>
                    <BsFillArrowRightCircleFill
                        role="button"
                        size={20}
                        className={`ms-3 text-primary ${disableIncreaseMonth ? 'pe-none opacity-50' : ''}`}
                        onClick={() => {
                            handleMonthChange(1);
                            increaseMonth();
                        }}
                        disabled={disableIncreaseMonth}
                    />
                </div>
                <div className="d-flex justify-content-center align-items-center m-3">
                    <select
                        value={selectedYear}
                        onChange={({ target: { value } }) => {
                            const newYear = parseInt(value);
                            changeYear(newYear);
                            addYearToList(newYear);
                        }}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select
                        value={months[selectedMonth]}
                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        }}
        selected={props.selected}
        onChange={props.onChange}
        customInput={props.customInput}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/yyyy"
        maxDate={props?.maxDate}
        className="form-control"
        wrapperClassName="w-100"
    />
};

export default DatePickerWithYearAndMonth;