import { useLocation } from "react-router-dom";
import classNames from "classnames";

// Custom Component
import Menu from "../Menu";

const SideBar = (props) => {
    const location = useLocation();

    return <div className={classNames("sidebar px-3 d-none d-lg-flex flex-column", { "is-open": props.isOpen, "d-lg-none": props.authenticatedRoute.includes(location.pathname) || props.stringRoute.includes(location.pathname)})}>
        <Menu />
    </div>
}

export default SideBar;