import { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import classNames from "classnames";

// Custom Component
import { TournamentCard } from "../Card";
import NoTournament from "./NoTournament";
import ErrorAlert from '../Alert/ErrorAlert';

// API Service
import { allTournamentService, allOrganizerTournamentService } from '../../service/dashboard.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

const AllTournament = () => {
    const user = useSelector(selectUser);

    const [allTournamentList, setAllTournamentList] = useState([])
    
    /**
        * @function fetchAllTournamentList
        * @params
        * @description fetch the list of all tournament
    */
    const fetchAllTournamentList = async () => {
        try {
            const result = await allTournamentService();
            if (result?.data?.status) {
                setAllTournamentList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchAllOrganizerTournamentList
        * @params
        * @description fetch the list of all tournament for organizer
    */
    const fetchAllOrganizerTournamentList = async () => {
        try {
            const result = await allOrganizerTournamentService();
            if (result?.data?.status) {
                setAllTournamentList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchAllOrganizerTournamentList();
        } else {
            fetchAllTournamentList();
        }
        // eslint-disable-next-line
    }, [])

    return <Row className={classNames({
        'my-3': allTournamentList.length > 0,
        'my-5': allTournamentList.length === 0
    })}>
        {allTournamentList.length > 0 ? allTournamentList.map((item, index) => {
            return <TournamentCard item={item} key={index} />
        })
            :
            <NoTournament />
        }
    </Row>;
};

export default AllTournament;