import { Row, Col } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { CiMail, CiLocationOn, CiCalendar } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { useSelector } from 'react-redux';
import classNames from "classnames";
import moment from 'moment';

// Images
import { logo } from '../../assets/images';
import { Avatar, User, Users, Men, Women } from '../../assets/images/svg';

// Redux-Functions
import { selectUser } from "../../redux/userSlice"

// Utils
import { convertEventType, convertAgeGroup, convertGender } from '../../utils/helper';

const Receipt = (props) => {
    const location = useLocation();

    const user = useSelector(selectUser);

    let event_details;

    if (props?.tournament?.player_details) {
        event_details = props?.tournament?.category_type === "INDIVIDUAL" ? [...new Set(props?.tournament?.player_details)] : props?.tournament?.player_details?.length > 0 ? [...new Set(props?.tournament?.player_details[0]?.team_event_type)] : []
    } else if (props?.tournament?.players) {
        event_details = props?.tournament?.category_type === "INDIVIDUAL" ? [...new Set(props?.tournament?.players)] : props?.tournament?.players?.length > 0 ? [...new Set(props?.tournament?.players[0]?.team_event_type)] : []
    }

    /**
        * @function convertNumberToWords
        * @params num
        * @description used to convert number to words
    */
    const convertNumberToWords = (num) => {
        const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
        const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
        const thousands = ["", "Thousand", "Million", "Billion"];

        if (num === 0) return "Zero";

        let words = '';
        let i = 0;

        while (num > 0) {
            let n = num % 1000;
            if (n !== 0) {
                let str = '';
                if (n > 99) {
                    str += ones[Math.floor(n / 100)] + " Hundred ";
                    n %= 100;
                }
                if (n > 19) {
                    str += tens[Math.floor(n / 10)] + " ";
                    n %= 10;
                }
                if (n > 9) {
                    str += teens[n - 10] + " ";
                } else {
                    str += ones[n] + " ";
                }
                words = str + thousands[i] + " " + words;
            }
            num = Math.floor(num / 1000);
            i++;
        }

        return words.trim();
    }

    return <div className={classNames('mt-vh-100', { 'd-none': !props?.pdfContentShow })}>
        <div className="d-flex flex-column pb-4" ref={props?.pdfRef}>
            <div className='bg-receipt px-4 py-5'>
                <div className="d-flex flex-column align-items-start w-100">
                    <span className='fs-30 fw-bold mb-3'>Receipt</span>
                    <img className='img-fluid mb-4' src={logo} alt='AFA' />
                    <span className='fs-48 fw-bold'>{props?.tournament?.organizer?.name}</span>
                    <div className="mt-2">
                        <CiLocationOn className="me-2" size={20} />
                        <span className='text-break'>{props?.tournament?.organizer?.address}</span>
                    </div>
                    <Row className="w-100 mt-2">
                        <Col xl={7}>
                            <CiMail className="me-2" size={20} />
                            <a className='text-dark text-decoration-none word-break-all' href={`mailto:${props?.tournament?.organizer?.email}`}>
                                <span className="fw-500 word-break-all">{props?.tournament?.organizer?.email}</span>
                            </a>
                        </Col>
                        <Col xl={5} className='mt-2 mt-xl-0 d-none d-xl-flex justify-content-xl-end align-items-start'>
                            <span className='me-0-point-3'>Date</span>
                            {[...Array(13)].map((x, i) => {
                                return <span className='d-none d-xl-block' key={i}>&nbsp;</span>
                            })}
                            <div>
                                <span className="me-2">:</span>
                                <span>{moment(new Date()).format("DD/MM/YYYY")}</span>
                            </div>
                            {location?.pathname?.includes("tournament") ?
                                <>
                                    {[...Array(25)].map((x, i) => {
                                        return <span className={classNames('d-none d-xl-block', {
                                            'me-0-point-45': i === 0
                                        })} key={i}>&nbsp;</span>
                                    })}
                                </>
                                :
                                <>
                                    {[...Array(26)].map((x, i) => {
                                        return <span className={classNames('d-none d-xl-block', {
                                            'me-0-point-3': i === 0
                                        })} key={i}>&nbsp;</span>
                                    })}
                                </>
                            }
                        </Col>
                    </Row>
                    <Row className="w-100 mt-2">
                        <Col xl={7}>
                            <BsTelephone className="me-2" size={20} />
                            <a className='text-dark text-decoration-none word-break-all' href={`tel:${props?.tournament?.organizer?.phone}`}>
                                <span className='word-break-all'>{props?.tournament?.organizer?.phone}</span>
                            </a>
                        </Col>
                        <Col xl={5} className='mt-2 mt-xl-0 d-flex d-xl-none justify-content-xl-end align-items-start'>
                            <Row className='w-100'>
                                <Col xs={5} sm={3} md={2} className='pe-0'>
                                    <span>Date</span>
                                </Col>
                                <Col xs={7} sm={9} md={10} className='ps-0'>
                                    <div>
                                        <span className="me-2">:</span>
                                        <span>{moment(new Date()).format("DD/MM/YYYY")}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl={5} className='mt-2 mt-xl-0 d-flex justify-content-xl-end align-items-start'>
                            <span className='d-none d-xl-block me-0-point-4'>Receipt no</span>
                            {[...Array(2)].map((x, i) => {
                                return <span className='d-none d-xl-block' key={i}>&nbsp;</span>
                            })}
                            <div className='d-none d-xl-flex flex-wrap align-items-center'>
                                <span className="d-none d-xl-block ms-2 ms-xl-0 me-2">:</span>
                                {props?.tournament?.player_details?.length > 0 &&
                                    <span>{props?.tournament?.player_details[0]?.receipt_no}</span>
                                }
                                {props?.tournament?.players?.length > 0 &&
                                    <span>{props?.tournament?.players[0]?.receipt_no}</span>
                                }
                            </div>
                            {[...Array(2)].map((x, i) => {
                                return <span className={classNames('d-none d-xl-block', {
                                    'me-0-point-5': i === 0
                                })} key={i}>&nbsp;</span>
                            })}
                            <Row className='d-xl-none w-100'>
                                <Col xs={5} sm={3} md={2} className='pe-0'>
                                    <span>Receipt no</span>
                                </Col>
                                <Col xs={7} sm={9} md={10} className='ps-0'>
                                    <div>
                                        <span className="me-2">:</span>
                                        {props?.tournament?.player_details?.length > 0 &&
                                            <span className='word-break-all'>{props?.tournament?.player_details[0]?.receipt_no}</span>
                                        }
                                        {props?.tournament?.players?.length > 0 &&
                                            <span className='word-break-all'>{props?.tournament?.players[0]?.receipt_no}</span>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='px-4'>
                <div className="d-flex flex-column align-items-start my-4">
                    <span className="fs-24 fw-bold mb-3 word-spacing-2">Tournament details</span>
                    <span className="fs-18 fw-bold mb-3 word-spacing-2">{props?.tournament?.name}</span>
                    <div>
                        <CiCalendar className="me-2" size={20} />
                        <span>{moment(new Date(props?.tournament?.tournament_start_date)).format("MM/DD/YYYY")} to {moment(new Date(props?.tournament?.tournament_end_date)).format("MM/DD/YYYY")}</span>
                    </div>
                    <div className="mt-2">
                        <BsTelephone className="me-2" size={20} />
                        <a className='text-dark text-decoration-none word-break-all' href={`tel:${props?.tournament?.phone_number}`}>
                            <span className='word-break-all'>{props?.tournament?.phone_number}</span>
                        </a>
                    </div>
                    <div className="mt-2">
                        <CiLocationOn className="me-2" size={20} />
                        <span className='text-break'>{props?.tournament?.address}</span>
                    </div>
                </div>
                <hr />
                <div className="d-flex flex-column align-items-start mt-3">
                    <span className="fs-24 fw-bold mb-4 word-spacing-2">Booking details</span>
                    {props?.tournament?.sports_type === "BADMINTON" ?
                        <span className="fs-20 fw-bold mb-3">Badminton</span>
                        :
                        props?.tournament?.sports_type === "PICKLEBALL" ?
                            <span className="fs-20 fw-bold mb-3">Pickleball</span>
                            :
                            <></>
                    }
                    {props?.tournament?.category_type === "INDIVIDUAL" &&
                        <>
                            <div className="mb-3">
                                <User className='me-2' />
                                <span>Individuals Event</span>
                            </div>
                            <span>Type of Event</span>
                            <div className='d-flex flex-wrap mb-3'>
                                {event_details?.length > 0 && event_details?.slice(0, 1)?.map((item, index) => {
                                    return <div className='d-flex align-items-center bg-primary text-light mt-2 me-2 p-2 rounded' key={index}>
                                        {(item?.event_type === 'MEN_SINGLE' || item?.event_type === 'MEN_DOUBLE' || item?.event_type === 'MIX_DOUBLE' || item?.event_type === 'MEN_3_3' || item?.event_type === 'MIX_3_3' || item?.event_type === 'JUNIOR_SINGLE' || item?.event_type === 'JUNIOR_DOUBLE') && <Men color='white' className='me-2' />}
                                        {(item?.event_type === 'WOMEN_SINGLE' || item?.event_type === 'WOMEN_DOUBLE' || item?.event_type === 'MIX_DOUBLE' || item?.event_type === 'WOMEN_3_3' || item?.event_type === 'MIX_3_3' || item?.event_type === 'JUNIOR_SINGLE' || item?.event_type === 'JUNIOR_DOUBLE') && <Women color='white' className='me-2' />}
                                        <span>
                                            {convertEventType(item?.event_type)}
                                            {item?.age_group && item?.age_value && ` (${convertAgeGroup(item?.age_group, item?.age_value)})`}
                                        </span>
                                    </div>
                                })}
                            </div>
                        </>
                    }
                    {props?.tournament?.category_type === "TEAM" &&
                        <>
                            <div className="mb-3">
                                <Users className="me-2" size={20} />
                                <span>Team Event</span>
                            </div>
                            <span>Type of Event</span>
                            <div className='d-flex flex-wrap mb-3'>
                                {event_details?.length > 0 && event_details?.map((item, index) => {
                                    return <div className='d-flex align-items-center bg-primary text-light mt-2 me-2 p-2 rounded' key={index}>
                                        {(item === 'MEN_SINGLE' || item === 'MEN_DOUBLE' || item === 'MIX_DOUBLE' || item === 'MEN_3_3' || item === 'MIX_3_3' || item === 'JUNIOR_SINGLE' || item === 'JUNIOR_DOUBLE') && <Men color='white' className='me-2' />}
                                        {(item === 'WOMEN_SINGLE' || item === 'WOMEN_DOUBLE' || item === 'MIX_DOUBLE' || item === 'WOMEN_3_3' || item === 'MIX_3_3' || item === 'JUNIOR_SINGLE' || item === 'JUNIOR_DOUBLE') && <Women color='white' className='me-2' />}
                                        <span>
                                            {convertEventType(item)}
                                            {props?.tournament?.player_details?.length > 0 &&
                                                ` (${props?.tournament?.player_details[0]?.team_event_age_group?.length > 0 && props?.tournament?.player_details[0]?.team_event_age_value?.length > 0 && convertAgeGroup(props?.tournament?.player_details[0]?.team_event_age_group[index], props?.tournament?.player_details[0]?.team_event_age_value[index])})`
                                            }
                                            {props?.tournament?.players?.length > 0 &&
                                                ` (${props?.tournament?.players[0]?.team_event_age_group?.length > 0 && props?.tournament?.players[0]?.team_event_age_value?.length > 0 && convertAgeGroup(props?.tournament?.players[0]?.team_event_age_group[index], props?.tournament?.players[0]?.team_event_age_value[index])})`
                                            }
                                        </span>
                                    </div>
                                })}
                            </div>
                        </>
                    }
                    <span className='mb-2'>Players ({props?.tournament?.player_details?.length || props?.tournament?.players?.length})</span>
                    <Row className='w-100 mx-0 d-xl-none'>
                        {props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details?.map((item, index) => {
                            return <Col xs={12} className='d-flex flex-column align-items-start border rounded mb-3 p-3'>
                                <span className='fs-16'>{index + 1}</span>
                                <span className='fs-18 fw-bold text-capitalize mb-2'>{item?.player_name}</span>
                                <span className='fs-16 mb-2'>{convertGender(item?.player_gender)}</span>
                                <a className='text-dark text-decoration-none word-break-all' href={`mailto:${item?.player_email}`}>
                                    <span className='fs-16 word-break-all'>{item?.player_email}</span>
                                </a>
                                <a className='text-dark text-decoration-none word-break-all' href={`tel:${item?.player_contact}`}>
                                    <span className='fs-16'>{item?.player_contact}</span>
                                </a>
                            </Col>
                        })}
                        {props?.tournament?.players?.length > 0 && props?.tournament?.players?.map((item, index) => {
                            return <Col xs={12} className='d-flex flex-column align-items-start border rounded mb-3 p-3'>
                                <span className='fs-16'>{index + 1}</span>
                                <span className='fs-18 fw-bold text-capitalize mb-2'>{item?.player_name}</span>
                                <span className='fs-16 mb-2'>{convertGender(item?.player_gender)}</span>
                                <a className='text-dark text-decoration-none word-break-all' href={`mailto:${item?.player_email}`}>
                                    <span className='fs-16 word-break-all'>{item?.player_email}</span>
                                </a>
                                <a className='text-dark text-decoration-none word-break-all' href={`tel:${item?.player_contact}`}>
                                    <span className='fs-16'>{item?.player_contact}</span>
                                </a>
                            </Col>
                        })}
                    </Row>
                    <table className="mt-2 mb-5 border w-100 d-none d-xl-inline-table">
                        <thead>
                            <tr class="text-secondary">
                                <th class="bg-receipt fw-bold text-dark text-truncate receipt-number-header p-2">No.</th>
                                <th class="bg-receipt fw-bold text-dark text-truncate p-2">Player name</th>
                                <th class="bg-receipt fw-bold text-dark text-truncate p-2">Players Gender</th>
                                <th class="bg-receipt fw-bold text-dark text-truncate p-2">Email address</th>
                                <th class="bg-receipt fw-bold text-dark text-truncate p-2">Phone number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details?.map((item, index) => {
                                return <tr key={index}>
                                    <td className='text-truncate p-2'>{index + 1}</td>
                                    <td className='text-capitalize text-truncate p-2'>{item?.player_name}</td>
                                    <td className='text-truncate p-2'>{convertGender(item?.player_gender)}</td>
                                    <td className='text-truncate p-2'>
                                        <a className='text-dark text-decoration-none word-break-all' href={`mailto:${item?.player_email}`}>
                                            {item?.player_email}
                                        </a>
                                    </td>
                                    <td className='text-truncate p-2'>
                                        <a className='text-dark text-decoration-none word-break-all' href={`tel:${item?.player_contact}`}>
                                            {item?.player_contact}
                                        </a>
                                    </td>
                                </tr>
                            })}
                            {props?.tournament?.players?.length > 0 && props?.tournament?.players?.map((item, index) => {
                                return <tr key={index}>
                                    <td className='text-truncate p-2'>{index + 1}</td>
                                    <td className='text-capitalize text-truncate p-2'>{item?.player_name}</td>
                                    <td className='text-truncate p-2'>{convertGender(item?.player_gender)}</td>
                                    <td className='text-truncate p-2'>
                                        <a className='text-dark text-decoration-none word-break-all' href={`mailto:${item?.player_email}`}>
                                            {item?.player_email}
                                        </a>
                                    </td>
                                    <td className='text-truncate p-2'>
                                        <a className='text-dark text-decoration-none word-break-all' href={`tel:${item?.player_contact}`}>
                                            {item?.player_contact}
                                        </a>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex flex-column align-items-start">
                    <span className="fs-24 fw-bold mb-4">User Details</span>
                    <div className='d-flex align-items-center mb-2'>
                        {user?.type === 'customer' && user?.profile_pic ?
                            <img className='profile-small-photo rounded-circle me-2' src={user?.profile_pic} alt={user?.username || user?.name} />
                            :
                            user?.type === 'organizer' && props?.tournament?.player_details?.length > 0 ?
                                <img className='profile-small-photo rounded-circle me-2' src={props?.tournament?.player_details[0]?.customer?.profile_pic} alt={props?.tournament?.player_details[0]?.customer?.username} />
                                :
                                <Avatar className="me-2" size={30} />
                        }
                        <div className='d-flex flex-column align-items-start'>
                            <span className='fw-bold'>{user?.type === 'customer' ? user?.username : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer?.username ? props?.tournament?.player_details[0]?.customer?.username : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer?.username ? props?.tournament?.players[0]?.customer?.username : user?.name}</span>
                            <a className='text-dark text-decoration-none word-break-all' href={`tel:${user?.type === 'customer' ? user?.country?.code + ' ' + user?.phone : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer?.country?.code && props?.tournament?.player_details[0]?.customer?.phone ? props?.tournament?.player_details[0]?.customer?.country?.code + ' ' + props?.tournament?.player_details[0]?.customer?.phone : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer?.country?.code && props?.tournament?.players[0]?.customer?.phone ? props?.tournament?.players[0]?.customer?.country?.code + ' ' + props?.tournament?.players[0]?.customer?.phone : user?.country?.code + ' ' + user?.phone}`}>
                                <span>{user?.type === 'customer' ? user?.country?.code + ' ' + user?.phone : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer?.country?.code && props?.tournament?.player_details[0]?.customer?.phone ? props?.tournament?.player_details[0]?.customer?.country?.code + ' ' + props?.tournament?.player_details[0]?.customer?.phone : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer?.country?.code && props?.tournament?.players[0]?.customer?.phone ? props?.tournament?.players[0]?.customer?.country?.code + ' ' + props?.tournament?.players[0]?.customer?.phone : user?.country?.code + ' ' + user?.phone}</span>
                            </a>
                        </div>
                    </div>
                    <a className='text-dark text-decoration-none word-break-all' href={`mailto:${user?.type === 'customer' ? user?.email : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer ? props?.tournament?.player_details[0]?.customer?.email : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer ? props?.tournament?.players[0]?.customer?.email : user?.email}`}>
                        <span className='fs-14 fw-500 word-break-all'>{user?.type === 'customer' ? user?.email : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer ? props?.tournament?.player_details[0]?.customer?.email : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer ? props?.tournament?.players[0]?.customer?.email : user?.email}</span>
                    </a>
                    <span className='fs-14 fw-500'>{user?.type === 'customer' ? convertGender(user?.gender) : (user?.type === 'organizer' && ((props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer?.gender))) ? convertGender(props?.tournament?.player_details[0]?.customer?.gender) : (user?.type === 'organizer' && ((props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer?.gender))) ? convertGender(props?.tournament?.players[0]?.customer?.gender) : convertGender(user?.gender)}</span>
                    <span className='fs-14 fw-500'>{user?.type === 'customer' ? user?.country?.name : props?.tournament?.player_details?.length > 0 && props?.tournament?.player_details[0]?.customer ? `${props?.tournament?.player_details[0]?.customer?.country?.name || ""}` : props?.tournament?.players?.length > 0 && props?.tournament?.players[0]?.customer ? `${props?.tournament?.players[0]?.customer?.country?.name || ""}` : user?.country?.name}</span>
                    <div className='d-flex flex-column w-100 align-items-start mt-3'>
                        <span className='fs-18 fw-bold me-4'>Total Price paid</span>
                        <span className='fs-12 me-4'>Amount Chargeable (in words)</span>
                        {props?.tournament?.category_type === "INDIVIDUAL" &&
                            <div className='d-flex flex-wrap justify-content-between align-items-center w-100 fs-18 fw-bold'>
                                {props?.tournament?.player_details &&
                                    <>
                                        <span className='me-4 text-capitalize word-spacing-2'>Ringgit Malaysia {convertNumberToWords(parseInt(props?.tournament?.player_details[0]?.event_price))} Only</span>
                                        <span>RM{parseFloat(props?.tournament?.player_details[0]?.event_price).toFixed(2)}</span>
                                    </>
                                }
                                {props?.tournament?.players?.length > 0 &&
                                    <>
                                        <span className='me-4 text-capitalize word-spacing-2'>Ringgit Malaysia {convertNumberToWords(parseInt(props?.tournament?.players[0]?.event_price))} Only</span>
                                        <span>RM{parseFloat(props?.tournament?.players[0]?.event_price).toFixed(2)}</span>
                                    </>
                                }
                            </div>
                        }
                        {props?.tournament?.category_type === "TEAM" &&
                            <div className='d-flex flex-wrap justify-content-between align-items-center w-100 fs-18 fw-bold'>
                                <span className='me-4 text-capitalize word-spacing-2'>Ringgit Malaysia {convertNumberToWords(parseInt(props?.tournament?.team_event_price))} Only</span>
                                <span>RM{parseFloat(props?.tournament?.team_event_price).toFixed(2)}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Receipt;