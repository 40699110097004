import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Custom Component
import { PrimaryOutlineButton } from '../Button';
import ErrorAlert from '../Alert/ErrorAlert';

// Images
import { noBank } from "../../assets/images";

// API Service
import { allBankService } from '../../service/bank.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const NoBank = (props) => {
    const user = useSelector(selectUser)

    const [bankList, setBankList] = useState([])

    /**
        * @function fetchBankList
        * @params
        * @description fetch the list of bank
    */
    const fetchBankList = async () => {
        try {
            const result = await allBankService();
            if (result?.data?.status) {
                setBankList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchBankList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className='d-flex flex-column justify-content-center align-items-center py-4'>
        <img className='ímg-fluid' src={noBank} alt='no-bank' />
        <span className='fs-18 fw-600 text-secondary text-center my-3'>You haven’t added Bank account yet!</span>
        {!user?.parent_id && bankList?.length > 0 &&
            <div>
                <PrimaryOutlineButton className='fs-14 fw-500 px-5' onClick={props?.handleAddBankAccountModal}>Add Bank Account</PrimaryOutlineButton>
            </div>
        }
    </div>
};

export default NoBank;