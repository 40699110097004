import { useState } from 'react';
import { Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import LoadingOverlay from 'react-loading-overlay';
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from 'react-redux';

// Custom Component
import ErrorText from '../TextField/ErrorText'
import { PrimaryButton, PrimaryOutlineButton } from '../Button';
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';

// Images
import { Message } from '../../assets/images/svg';

// Utility Service
import { setUserSession, updateUserSession } from '../../utils/AuthService';

// API Service
import { signUpOtpVerifyService, loginOtpVerifyService, forgetPasswordOtpVerifyService, resendOtpService } from '../../service/auth.service';
import { getCustomerProfileService } from '../../service/profile.service';

// Redux-Functions
import { setToken, setUser } from "../../redux/userSlice"

const OTPVerificationModal = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined

    const { control, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
        * @function fetchUserInfo
        * @params
        * @description fetch the info of user
    */
    const fetchUserInfo = async () => {
        try {
            const result = await getCustomerProfileService();
            if (result?.data?.status) {
                dispatch(setUser({
                    ...result.data?.data,
                    type: 'customer'
                }))
                updateUserSession({
                    ...result.data?.data,
                    type: 'customer'
                })
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function resendOtp
        * @params
        * @description used to resend otp
    */
    const resendOtp = async () => {
        try {
            setLoading(true)
            const result = await resendOtpService({
                phone: props?.phone
            });
            if (!result?.data?.status) {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
        * @function onSubmit
        * @params data
        * @description used to verify otp
    */
    const onSubmit = async (data) => {
        try {
            let result;
            if (props?.forgotPassword) {
                setLoading(true)
                result = await forgetPasswordOtpVerifyService({
                    otp: data?.otp,
                    phone: props?.phone
                });
                if (result?.data?.status) {
                    navigate('/reset-password', {
                        state: {
                            phone: props?.phone,
                            reset_otp_token: result?.data?.data?.reset_otp_token
                        }
                    });
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
                setLoading(false)
            }
            if (props?.login) {
                setLoading(true)
                result = await loginOtpVerifyService({
                    otp: data?.otp,
                    phone: props?.phone
                });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    dispatch(setToken(result?.data?.data?.token))
                    dispatch(setUser({
                        ...result.data?.data?.customer,
                        type: 'customer'
                    }))
                    setUserSession(result.data?.data?.token, {
                        ...result.data?.data?.customer,
                        type: 'customer'
                    })
                    await fetchUserInfo()
                    navigate('/');
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
                setLoading(false)
            }
            if (props?.signup) {
                setLoading(true)
                result = await signUpOtpVerifyService({
                    otp: data?.otp,
                    phone: props?.phone
                });
                if (result?.data?.status) {
                    SuccessAlert(result?.data?.message)
                    dispatch(setToken(result?.data?.data?.token))
                    dispatch(setUser({
                        ...result.data?.data?.customer,
                        type: 'customer'
                    }))
                    setUserSession(result.data?.data?.token, {
                        ...result.data?.data?.customer,
                        type: 'customer'
                    })
                    await fetchUserInfo()
                    navigate('/');
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='border-0' closeButton />
            <Modal.Body className='d-flex flex-column align-items-center'>
                <Message />
                <span className='fs-18 fw-600 text-dark mt-3'>Please check your Message</span>
                <span className='fs-14 fw-400 text-secondary'>We've sent a code to {props?.country} {props?.phone}</span>
                <Form className='mt-3 w-100' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className='px-4'>
                        <Controller
                            control={control}
                            name={`otp`}
                            render={({ field }) => (
                                <OtpInput
                                    containerStyle='verfiction-input-div justify-content-between mb-3'
                                    inputStyle='verfiction-input form-control rounded-2'
                                    focusStyle='border-0'
                                    value={field.value}
                                    onChange={field.onChange}
                                    numInputs={4}
                                    renderInput={(props) => <input {...props} />}
                                />
                            )}
                            rules={{ required: 'Please enter your 4 digit OTP' }}
                        />
                        {errors.otp && <ErrorText className='mt-3'>{errors.otp.message}</ErrorText>}
                    </Form.Group>

                    <div className="mb-5 px-4">
                        <span className='fs-14 fw-500 text-center'>
                            Didn’t get a code?
                            <span role='button' className='ms-1 fw-bold text-primary' onClick={resendOtp}>Click to resend.</span>
                        </span>
                    </div>

                    <Row className='mb-4'>
                        <Col xs={6}>
                            <PrimaryOutlineButton className='fs-14 fw-500 footer-text border-secondary' onClick={props.handleClose}>Cancel</PrimaryOutlineButton>
                        </Col>
                        <Col xs={6}>
                            <PrimaryButton className='fs-14 fw-500' disabled={loading} loading={loading} type="submit">Verify</PrimaryButton>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default OTPVerificationModal;