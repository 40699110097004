// Images
import { noPlayer } from "../../assets/images";

const NoPlayer = () => {
    return <div className='my-5 text-center'>
        <img className='img-fluid mt-5 mb-3' src={noPlayer} alt='No Player' />
        <p className='fs-24 fw-600'>Players not joined yet!</p>
    </div>
};

export default NoPlayer;