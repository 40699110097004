import axios from "axios"

/**
    * @function createPaymentService
    * @param data
    * @description This function is used to create payment for entering tournament
*/
export const createPaymentService = async(data) =>{
    try {
        const result = await axios.post(`/customer/payment/create-payment`, data)
        return result;
    } catch (error) {
        return error;
    }
}