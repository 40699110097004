import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

// Custom Component
import { PrimaryButton } from '../../components/Button'
import AuthFooter from '../../components/Common/AuthFooter'
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Images
import { logo, smallLogo, authentication } from '../../assets/images'

// Utility Service
import { getToken } from '../../utils/AuthService';

// API Service
import { loginAFAService } from '../../service/auth.service';

const SignIn = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // navigate to home page if login
        if (getToken()) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
        * @function handleAFASignIn
        * @params
        * @description handle the sign in via AFA
    */
    const handleAFASignIn = async () => {
        try {
            const result = await loginAFAService();
            if (result?.data?.status) {
                window.location.href = result?.data?.data?.url
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Sign In | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container fluid className='pe-lg-0'>
            <Row>
                <Col xs={12} lg={6} className='d-flex flex-column align-items-start'>
                    <Link to='/'>
                        <img className="img-fluid ms-4 mt-4" src={logo} alt="AFA" />
                    </Link>
                    <Row className="justify-content-center align-items-center flex-grow-1 w-100 mt-5">
                        <Col xs={12} sm={7} className='border border-2 p-4'>
                            <span className='fs-30 fw-bold'>Sign In</span>
                            <PrimaryButton className='mt-4 mb-2' onClick={handleAFASignIn}>
                                <img className="img-fluid me-2" src={smallLogo} alt="AFA" />
                                Sign In via AFA
                            </PrimaryButton>
                            <hr className='mx-5 my-3' />
                            <Link to='/organizer-login' className='text-decoration-none'>
                                <div role='button' className='my-3 bg-danger bg-opacity-10 text-center rounded py-2'>
                                    <span className='text-primary fw-600 px-3'>Sign in as Organizer</span>
                                </div>
                            </Link>
                        </Col>
                        <AuthFooter />
                    </Row>
                </Col>
                <Col xs={12} lg={6} className='d-none d-lg-block'>
                    <img className="img-fluid" src={authentication} alt="Authentication" />
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default SignIn;