import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { BiArrowBack } from "react-icons/bi";

// Custom Component
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { getCustomerPrivacyPolicyService, getOrganizerPrivacyPolicyService } from '../../service/variable.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const [privacyPolicy, setPrivacyPolicy] = useState()
    const user = useSelector(selectUser)

    /**
        * @function fetchCustomerPrivacyPolicy
        * @params
        * @description fetch the content of privacy policy for customer
    */
    const fetchCustomerPrivacyPolicy = async () => {
        try {
            const result = await getCustomerPrivacyPolicyService();
            if (result?.data?.status) {
                setPrivacyPolicy(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchOrganizerPrivacyPolicy
        * @params
        * @description fetch the content of privacy policy for organizer
    */
    const fetchOrganizerPrivacyPolicy = async () => {
        try {
            const result = await getOrganizerPrivacyPolicyService();
            if (result?.data?.status) {
                setPrivacyPolicy(result?.data?.data?.value)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchOrganizerPrivacyPolicy()
        } else {
            fetchCustomerPrivacyPolicy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Privacy Policy | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container className='my-4'>
            <Row>
                <Col xs={12} className='position-relative'>
                    <BiArrowBack role="button" size={20} className='position-absolute top-0 start-0 mt-2 ms-2' onClick={() => navigate(-1)} />
                    <div className='mt-5'>
                        {privacyPolicy && parse(privacyPolicy)}
                    </div>
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default PrivacyPolicy;