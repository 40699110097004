import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import { BsCheckLg } from "react-icons/bs";
import classNames from "classnames";
import moment from 'moment';
import _ from 'lodash';

// Custom Component
import Breadcrumb from "../../components/Common/Breadcrumb";
import { PrimaryButton } from "../../components/Button";
import ErrorText from '../../components/TextField/ErrorText'
import DatePickerWithYearAndMonth from '../../components/DatePickerWithYearAndMonth/DatePickerWithYearAndMonth';
import ErrorAlert from '../../components/Alert/ErrorAlert';

// Images
import { Men, Women } from '../../assets/images/svg';

// API Service
import { getCustomerSingleTournamentService, enterTournamentService, editCustomerPlayerService } from '../../service/tournament.service';

// Utils
import { convertEventType, convertAgeGroup } from '../../utils/helper';

const EnterTournament = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [tournament, setTournament] = useState();
    const [teamEventType, setTeamEventType] = useState([]);
    const [playerIdList, setPlayerIdList] = useState([]);

    const countryList = [
        "Malaysia",
        "Denmark",
        "China",
        "Thailand",
        "Singapore",
        "Indonesia",
        "Vietnam",
        "Philippines",
        "Cambodia",
        "India",
        "England",
        "Japan",
        "South Korea",
        "Taiwan",
        "Hong Kong",
        "Others"
    ]

    const { register, control, watch, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur"
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "player_details"
    });

    const watchEventType = watch("event_type")
    const allWatchedValues = watch({
        nest: true
    });

    const reactSelectCustomStyle1 = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: "300px",
            border: state.isFocused && "1px solid #FA5000",
            boxShadow: state.isFocused && "0px 0px 2px #FA5000",
            "&:hover": {
                border: "1px solid #FA5000",
                boxShadow: "0px 0px 2px #FA5000"
            }
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#FA5000' : 'white',
                "&:hover": {
                    backgroundColor: "#FA5000",
                    color: 'white'
                }
            }
        }
    }

    const reactSelectCustomStyle2 = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            width: "300px",
            border: state.isFocused && "1px solid #FA5000",
            boxShadow: state.isFocused && "0px 0px 2px #FA5000",
            "&:hover": {
                border: "1px solid #FA5000",
                boxShadow: "0px 0px 2px #FA5000"
            }
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#FA5000' : 'white',
                "&:hover": {
                    backgroundColor: "#FA5000",
                    color: 'white'
                }
            }
        }
    }

    useEffect(() => {
        if (watchEventType && tournament?.category_type === "INDIVIDUAL") {
            if ((watchEventType === "MEN_SINGLE" || watchEventType === "WOMEN_SINGLE" || watchEventType === "JUNIOR_SINGLE") && fields?.length > 1) {
                setValue('player_details', allWatchedValues?.player_details.slice(0, 1));
            }
            if ((watchEventType === "MEN_DOUBLE" || watchEventType === "WOMEN_DOUBLE" || watchEventType === "MIX_DOUBLE" || watchEventType === "JUNIOR_DOUBLE") && fields?.length > 2) {
                setValue('player_details', allWatchedValues?.player_details.slice(0, 2));
            }
            if ((watchEventType === "MEN_3_3" || watchEventType === "WOMEN_3_3" || watchEventType === "MIX_3_3") && fields?.length > 3) {
                setValue('player_details', allWatchedValues?.player_details.slice(0, 3));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournament, watchEventType])

    /**
        * @function fetchCustomerSingleTournament
        * @params
        * @description fetch the single tournament details for customer
    */
    const fetchCustomerSingleTournament = async () => {
        try {
            const result = await getCustomerSingleTournamentService(state?.tournamentId);
            if (result?.data?.status) {
                setTournament(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (state?.tournamentId) {
            fetchCustomerSingleTournament()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        if (teamEventType) {
            setValue(`team_event_type`, teamEventType)
        }
        // eslint-disable-next-line
    }, [teamEventType])

    useEffect(() => {
        if (tournament) {
            if (state?.edit) {
                const player_details = []
                if (tournament?.category_type === "INDIVIDUAL" && state?.player_details?.length > 0) {
                    setValue("event_type", state?.player_details[0]?.event_type)
                    setValue(`${state?.player_details[0]?.event_type}_age_group_value`, {
                        label: <span>
                            {(state?.player_details[0]?.event_type === "MEN_SINGLE" || state?.player_details[0]?.event_type === "MEN_DOUBLE" || state?.player_details[0]?.event_type === "MIX_DOUBLE" || state?.player_details[0]?.event_type === "MEN_3_3" || state?.player_details[0]?.event_type === "MIX_3_3" || state?.player_details[0]?.event_type === "JUNIOR_SINGLE" || state?.player_details[0]?.event_type === "JUNIOR_DOUBLE") &&
                                <Men className='me-2' color={'white'} />
                            }
                            {(state?.player_details[0]?.event_type === "WOMEN_SINGLE" || state?.player_details[0]?.event_type === "WOMEN_DOUBLE" || state?.player_details[0]?.event_type === "MIX_DOUBLE" || state?.player_details[0]?.event_type === "WOMEN_3_3" || state?.player_details[0]?.event_type === "MIX_3_3" || state?.player_details[0]?.event_type === "JUNIOR_SINGLE" || state?.player_details[0]?.event_type === "JUNIOR_DOUBLE") &&
                                <Women className='me-2' color={'white'} />
                            }
                            {`${convertEventType(state?.player_details[0]?.event_type)} (${convertAgeGroup(state?.player_details[0]?.age_group, state?.player_details[0]?.age_value)})`}
                        </span>,
                        value: `${state?.player_details[0]?.age_group}|${state?.player_details[0]?.age_value}`
                    })
                    setValue(`${state?.player_details[0]?.event_type}_age_group`, state?.player_details[0]?.age_group)
                    setValue(`${state?.player_details[0]?.event_type}_age_value`, state?.player_details[0]?.age_value)
                }
                if (tournament?.category_type === "TEAM" && state?.player_details?.length > 0) {
                    setTeamEventType(state?.player_details[0]?.team_event_type)
                    if (state?.player_details[0]?.team_event_type?.length > 0) {
                        state?.player_details[0]?.team_event_type?.forEach((item, index) => {
                            setValue(`${item}_age_group_value`, {
                                label: <span>
                                    {(item === "MEN_SINGLE" || item === "MEN_DOUBLE" || item === "MIX_DOUBLE" || item === "MEN_3_3" || item === "MIX_3_3" || item === "JUNIOR_SINGLE" || item === "JUNIOR_DOUBLE") &&
                                        <Men className='me-2' color={'white'} />
                                    }
                                    {(item === "WOMEN_SINGLE" || item === "WOMEN_DOUBLE" || item === "MIX_DOUBLE" || item === "WOMEN_3_3" || item === "MIX_3_3" || item === "JUNIOR_SINGLE" || item === "JUNIOR_DOUBLE") &&
                                        <Women className='me-2' color={'white'} />
                                    }
                                    {`${convertEventType(item)} (${convertAgeGroup(state?.player_details[0]?.team_event_age_group[index], state?.player_details[0]?.team_event_age_value[index])})`}
                                </span>,
                                value: `${state?.player_details[0]?.team_event_age_group[index]}|${state?.player_details[0]?.team_event_age_value[index]}`
                            })
                            setValue(`${item}_age_group`, state?.player_details[0]?.team_event_age_group[index])
                            setValue(`${item}_age_value`, state?.player_details[0]?.team_event_age_value[index])
                        })
                    }
                }
                state?.player_details?.forEach((item) => {
                    player_details.push({
                        player_name: item?.player_name,
                        club_name: item?.club_name,
                        player_email: item?.player_email,
                        player_contact: item?.player_contact,
                        player_gender: item?.player_gender,
                        player_dob: new Date(item?.player_dob),
                        player_idcard: item?.player_idcard,
                        nationality: item?.nationality,
                        proffesional_player: item?.proffesional_player
                    })
                })
                setPlayerIdList(state?.player_details?.map(item => item?.id))
                setValue('player_details', player_details);
            } else {
                setValue('player_details', [
                    {
                        player_name: "",
                        club_name: "",
                        player_email: "",
                        player_contact: "",
                        player_gender: "MALE",
                        player_dob: "",
                        player_idcard: "",
                        nationality: "",
                        proffesional_player: false
                    }
                ]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, tournament])

    /**
        * @function onSubmit
        * @params formdata
        * @description used to create player
    */
    const onSubmit = async (formdata) => {
        try {
            const data = new FormData()
            if (tournament?.category_type === "INDIVIDUAL" && !formdata?.event_type) {
                ErrorAlert('You must select a event')
                return
            }
            if (tournament?.category_type === "INDIVIDUAL" && (formdata?.event_type === "MEN_DOUBLE" || formdata?.event_type === "WOMEN_DOUBLE" || formdata?.event_type === "MIX_DOUBLE" || formdata?.event_type === "JUNIOR_DOUBLE") && formdata[`${formdata?.event_type}_age_group`] === "SEMI_PRO" && formdata?.player_details?.every(item => !item?.proffesional_player)) {
                ErrorAlert("Tournament requires at least one 'State/District/National Player'.")
                return
            }
            if (tournament?.category_type === "INDIVIDUAL" && (formdata?.event_type === "MEN_DOUBLE" || formdata?.event_type === "WOMEN_DOUBLE" || formdata?.event_type === "MIX_DOUBLE" || formdata?.event_type === "JUNIOR_DOUBLE") && formdata[`${formdata?.event_type}_age_group`] === "SEMI_PRO" && formdata?.player_details?.every(item => item?.proffesional_player)) {
                ErrorAlert("Tournament requires at least one 'Amateur Player'.")
                return
            }
            if (tournament?.category_type === "INDIVIDUAL" && formdata?.event_type) {
                if (!formdata[`${formdata?.event_type}_age_group`] || !formdata[`${formdata?.event_type}_age_value`]) {
                    ErrorAlert('You must select a event')
                    return
                }
            }
            if (tournament?.category_type === "TEAM") {
                if (Array.isArray(teamEventType)) {
                    let noEvent = true;
                    teamEventType?.forEach((item) => {
                        if (formdata[`${item}_age_group`] && formdata[`${item}_age_value`]) {
                            noEvent = false;
                        }
                    })
                    if (noEvent) {
                        ErrorAlert('You must select a event')
                        return
                    }
                } else {
                    if (!teamEventType) {
                        ErrorAlert('You must select a event')
                        return
                    } else {
                        if (!formdata[`${teamEventType}_age_group`] || !formdata[`${teamEventType}_age_value`]) {
                            ErrorAlert('You must select a event')
                            return
                        }
                    }
                }
            }
            if (tournament?.category_type === "INDIVIDUAL" && formdata?.event_type === "MIX_DOUBLE") {
                if (formdata?.player_details?.filter(item => item.player_gender === "MALE")?.length === 0 || formdata?.player_details?.filter(item => item.player_gender === "FEMALE")?.length === 0) {
                    ErrorAlert('You must select a male & female for Mix Double Event')
                    return
                }
            }
            if (tournament?.category_type === "INDIVIDUAL" && (formdata?.event_type === "MEN_SINGLE" || formdata?.event_type === "MEN_DOUBLE" || formdata?.event_type === "MEN_3_3")) {
                if (formdata?.player_details?.filter(item => item.player_gender === "FEMALE")?.length > 0) {
                    ErrorAlert('You must select male for Men Event')
                    return
                }
            }
            if (tournament?.category_type === "INDIVIDUAL" && (formdata?.event_type === "WOMEN_SINGLE" || formdata?.event_type === "WOMEN_DOUBLE" || formdata?.event_type === "WOMEN_3_3")) {
                if (formdata?.player_details?.filter(item => item.player_gender === "MALE")?.length > 0) {
                    ErrorAlert('You must select female for Women Event')
                    return
                }
            }
            data.append('tournament_id', state?.tournamentId)
            var teamEventIndex = 0
            if (formdata?.player_details?.length > 0) {
                formdata?.player_details?.forEach((item, index) => {
                    if (formdata?.event_type && tournament?.category_type === "INDIVIDUAL") {
                        data.append(`player_details[${index}][event_type]`, formdata?.event_type)
                        if (!formdata[`${formdata?.event_type}_age_group`]) {
                            ErrorAlert('You must select age group with event')
                            return
                        }
                        data.append(`player_details[${index}][age_group]`, formdata[`${formdata?.event_type}_age_group`])
                        data.append(`player_details[${index}][age_value]`, parseInt(formdata[`${formdata?.event_type}_age_value`]))
                    }
                    if (teamEventType && !Array.isArray(teamEventType) && tournament?.category_type === "TEAM") {
                        data.append(`player_details[${index}][event_type]`, teamEventType)
                        if (!formdata[`${teamEventType}_age_group`]) {
                            ErrorAlert('You must select age group with event')
                            return
                        }
                        data.append(`player_details[${index}][age_group]`, formdata[`${teamEventType}_age_group`])
                        data.append(`player_details[${index}][age_value]`, parseInt(formdata[`${teamEventType}_age_value`]))
                    }
                    if (teamEventType && Array.isArray(teamEventType) && teamEventType?.length > 0 && tournament?.category_type === "TEAM") {
                        if (teamEventType?.length > teamEventIndex) {
                            if (teamEventType?.filter(teamEventItem => formdata[`${teamEventItem}_age_group`] && formdata[`${teamEventItem}_age_value`])?.length > 0) {
                                const element = teamEventType?.filter(teamEventItem => formdata[`${teamEventItem}_age_group`] && formdata[`${teamEventItem}_age_value`])[teamEventIndex]
                                data.append(`player_details[${index}][event_type]`, element)
                                data.append(`player_details[${index}][age_group]`, formdata[`${element}_age_group`])
                                data.append(`player_details[${index}][age_value]`, parseInt(formdata[`${element}_age_value`]))
                                if (teamEventType?.filter(teamEventItem => formdata[`${teamEventItem}_age_group`] && formdata[`${teamEventItem}_age_value`])?.length >= teamEventIndex + 2) {
                                    teamEventIndex = teamEventIndex + 1;
                                }
                            }
                        }
                    }
                    if (item?.player_name) {
                        data.append(`player_details[${index}][player_name]`, item?.player_name)
                    }
                    if (item?.club_name) {
                        data.append(`player_details[${index}][club_name]`, item?.club_name)
                    }
                    if (item?.player_email) {
                        data.append(`player_details[${index}][player_email]`, item?.player_email)
                    }
                    if (item?.player_contact) {
                        data.append(`player_details[${index}][player_contact]`, item?.player_contact)
                    }
                    if (item?.player_gender) {
                        data.append(`player_details[${index}][player_gender]`, item?.player_gender)
                    }
                    if (item?.player_dob) {
                        data.append(`player_details[${index}][player_dob]`, moment(item?.player_dob).format("YYYY-MM-DD"))
                    }
                    if (item?.player_idcard) {
                        data.append(`player_details[${index}][player_idcard]`, item?.player_idcard)
                    }
                    if (item?.nationality) {
                        data.append(`player_details[${index}][nationality]`, item?.nationality)
                    }
                    data.append(`player_details[${index}][proffesional_player]`, item?.proffesional_player ? 1 : 0)
                })
                if (tournament?.category_type === "TEAM") {
                    if (Array.isArray(teamEventType)) {
                        var index = 0;
                        for (const item of teamEventType) {
                            if (formdata[`${item}_age_group`] && formdata[`${item}_age_value`]) {
                                data.append(`team_event_type[${index}]`, item)
                                data.append(`team_event_age_group[${index}]`, formdata[`${item}_age_group`])
                                if (item === 'OPEN' || item === 'AMATEUR' || item === 'BAKAT_BARU') {
                                    data.append(`team_event_age_value[${index}]`, 0)
                                } else {
                                    data.append(`team_event_age_value[${index}]`, formdata[`${item}_age_value`])
                                }
                                index = index + 1;
                            }
                        }
                    } else {
                        if (formdata[`${teamEventType}_age_group`] && formdata[`${teamEventType}_age_value`]) {
                            data.append(`team_event_type[0]`, teamEventType)
                            data.append(`team_event_age_group[0]`, formdata[`${teamEventType}_age_group`])
                            if (teamEventType === 'OPEN' || teamEventType === 'AMATEUR' || teamEventType === 'BAKAT_BARU') {
                                data.append(`team_event_age_value[0]`, 0)
                            } else {
                                data.append(`team_event_age_value[0]`, formdata[`${teamEventType}_age_value`])
                            }
                        }
                    }
                }
            }
            if (state?.edit) {
                playerIdList?.forEach((item, index) => {
                    data.append(`player_ids[${index}]`, item)
                })
                data.append(`team_id`, state?.player_details?.find(item => item?.team_id)?.team_id)
            }
            setLoading(true)
            let result
            if (state?.edit) {
                result = await editCustomerPlayerService({
                    id: state?.tournamentId,
                    data
                });
            }
            else {
                result = await enterTournamentService(data);
            }
            if (result?.data?.status) {
                navigate('/tournament-summary', {
                    state: {
                        tournament: result?.data?.data
                    }
                });
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Join Tournament | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        {tournament &&
            <>
                <Breadcrumb text='Join Tournament' />
                <p className='fs-25 fw-600 mt-4'>Join Tournament</p>
                <Form className='w-100 mb-4' onSubmit={handleSubmit(onSubmit)}>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <span className='fs-20 fw-600'>Players</span>
                        <div role='button' className={classNames('bg-danger bg-opacity-10 rounded py-1 px-3', { "d-none": (tournament?.category_type === "INDIVIDUAL" && (allWatchedValues?.event_type === "MEN_SINGLE" || allWatchedValues?.event_type === "WOMEN_SINGLE" || allWatchedValues?.event_type === "JUNIOR_SINGLE")) || (tournament?.category_type === "INDIVIDUAL" && (allWatchedValues?.event_type === "MEN_DOUBLE" || allWatchedValues?.event_type === "WOMEN_DOUBLE" || allWatchedValues?.event_type === "MIX_DOUBLE" || allWatchedValues?.event_type === "JUNIOR_DOUBLE") && fields.length === 2) || (tournament?.category_type === "INDIVIDUAL" && (allWatchedValues?.event_type === "MEN_3_3" || allWatchedValues?.event_type === "WOMEN_3_3" || allWatchedValues?.event_type === "MIX_3_3") && fields?.length === 3) })} onClick={() => {
                            append({
                                player_name: "",
                                club_name: "",
                                player_email: "",
                                player_contact: "",
                                player_gender: "MALE",
                                player_dob: "",
                                player_idcard: "",
                                nationality: ""
                            })
                        }}>
                            <span className='text-primary fw-bold'>+ Add Player</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span className='fw-600'>Available Individual Event</span>
                            <div className='d-flex flex-wrap align-items-center mb-3'>
                                {tournament?.category_type === "INDIVIDUAL" && [...new Map(tournament?.event_details?.map(item => [item.event_type, item])).values()]?.length > 0 && [...new Map(tournament?.event_details?.map(item => [item.event_type, item])).values()]?.map((item, index) => {
                                    return <div key={index}>
                                        <Form.Group className='me-2'>
                                            <Form.Check
                                                className='event-type ps-0 mt-3'
                                                type='radio'
                                                label={<>
                                                    <Controller
                                                        name={`${item?.event_type}_age_group_value`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                classNamePrefix={allWatchedValues[`${item?.event_type}_age_group_value`] && allWatchedValues?.event_type === item?.event_type && 'select'}
                                                                placeholder={convertEventType(item?.event_type)}
                                                                styles={reactSelectCustomStyle1}
                                                                options={tournament?.event_details?.filter(eventItem => eventItem?.event_type === item?.event_type).map((eventItem) => {
                                                                    return {
                                                                        value: `${eventItem?.age_group}|${eventItem?.age_value}`,
                                                                        label: <span>
                                                                            {(item?.event_type === "MEN_SINGLE" || item?.event_type === "MEN_DOUBLE" || item?.event_type === "MIX_DOUBLE" || item?.event_type === "MEN_3_3" || item?.event_type === "MIX_3_3" || item?.event_type === "JUNIOR_SINGLE" || item?.event_type === "JUNIOR_DOUBLE") &&
                                                                                <Men className='me-2' color='currentcolor' />
                                                                            }
                                                                            {(item?.event_type === "WOMEN_SINGLE" || item?.event_type === "WOMEN_DOUBLE" || item?.event_type === "MIX_DOUBLE" || item?.event_type === "WOMEN_3_3" || item?.event_type === "MIX_3_3" || item?.event_type === "JUNIOR_SINGLE" || item?.event_type === "JUNIOR_DOUBLE") &&
                                                                                <Women className='me-2' color='currentcolor' />
                                                                            }
                                                                            {`${convertEventType(item?.event_type)} (${convertAgeGroup(eventItem?.age_group, eventItem?.age_value)})`}
                                                                        </span>
                                                                    }
                                                                })}
                                                                isSearchable={false}
                                                                {...field}
                                                                onChange={(value) => {
                                                                    _.keys(_.pickBy(allWatchedValues, (v, k) => {
                                                                        return _.includes(k, 'age_group_value');
                                                                    }))?.forEach((key) => {
                                                                        if (key !== `${item?.event_type}_age_group_value`) {
                                                                            setValue(key, null)
                                                                            setValue(key.replace('_age_group_value', '_age_group'), null)
                                                                            setValue(key.replace('_age_group_value', '_age_value'), null)
                                                                        }
                                                                    })
                                                                    setValue(`${item?.event_type}_age_group_value`, value)
                                                                    setValue(`${item?.event_type}_age_group`, value?.value?.split("|")[0])
                                                                    setValue(`${item?.event_type}_age_value`, value?.value?.split("|")[1])
                                                                    setValue(`event_type`, item?.event_type)
                                                                }}
                                                                isClearable={true}
                                                            />
                                                        )}
                                                        rules={{
                                                            required: allWatchedValues?.event_type === item?.event_type ? 'Age Group is required' : false
                                                        }}
                                                    />
                                                </>
                                                }
                                                id={`${item?.event_type}_${index}`}
                                                value={item?.event_type}
                                                {...register(`event_type`)}
                                            />
                                        </Form.Group>
                                    </div>
                                })}
                                {tournament?.category_type === "TEAM" && [...new Map(tournament?.event_details?.map(item => [item.event_type, item])).values()]?.length > 0 && [...new Map(tournament?.event_details?.map(item => [item.event_type, item])).values()]?.map((item, index) => {
                                    return <div className='d-flex flex-wrap align-items-center' key={index}>
                                        <Form.Group className='me-2'>
                                            <Form.Check
                                                className='event-type ps-0 mt-3 me-2'
                                                type='checkbox'
                                                label={
                                                    <Controller
                                                        name={`${item?.event_type}_age_group_value`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                classNamePrefix={allWatchedValues[`${item?.event_type}_age_group_value`] && 'select'}
                                                                placeholder={convertEventType(item?.event_type)}
                                                                styles={reactSelectCustomStyle2}
                                                                options={tournament?.event_details?.filter(eventItem => eventItem?.event_type === item?.event_type).map((eventItem) => {
                                                                    return {
                                                                        value: `${eventItem?.age_group}|${eventItem?.age_value}`,
                                                                        label: <span>
                                                                            {(item?.event_type === "MEN_SINGLE" || item?.event_type === "MEN_DOUBLE" || item?.event_type === "MIX_DOUBLE" || item?.event_type === "MEN_3_3" || item?.event_type === "MIX_3_3" || item?.event_type === "JUNIOR_SINGLE" || item?.event_type === "JUNIOR_DOUBLE") &&
                                                                                <Men className='me-2' color='currentcolor' />
                                                                            }
                                                                            {(item?.event_type === "WOMEN_SINGLE" || item?.event_type === "WOMEN_DOUBLE" || item?.event_type === "MIX_DOUBLE" || item?.event_type === "WOMEN_3_3" || item?.event_type === "MIX_3_3" || item?.event_type === "JUNIOR_SINGLE" || item?.event_type === "JUNIOR_DOUBLE") &&
                                                                                <Women className='me-2' color='currentcolor' />
                                                                            }
                                                                            {`${convertEventType(item?.event_type)} (${convertAgeGroup(eventItem?.age_group, eventItem?.age_value)})`}
                                                                            <BsCheckLg className={classNames('ms-2', {
                                                                                'd-none': teamEventType?.length > 0 && !teamEventType?.includes(item?.event_type)
                                                                            })} />
                                                                        </span>
                                                                    }
                                                                })}
                                                                isSearchable={false}
                                                                {...field}
                                                                onChange={(value) => {
                                                                    _.keys(_.pickBy(allWatchedValues, (v, k) => {
                                                                        return _.includes(k, 'age_group_value');
                                                                    }))?.forEach((key) => {
                                                                        if (key !== `${item?.event_type}_age_group_value`) {
                                                                            setValue(key, null)
                                                                            setValue(key.replace('_age_group_value', '_age_group'), null)
                                                                            setValue(key.replace('_age_group_value', '_age_value'), null)
                                                                        }
                                                                    })
                                                                    setValue(`${item?.event_type}_age_group_value`, value)
                                                                    setValue(`${item?.event_type}_age_group`, value?.value?.split("|")[0])
                                                                    setValue(`${item?.event_type}_age_value`, value?.value?.split("|")[1])
                                                                    if (Array.isArray(teamEventType) && !teamEventType?.includes(item?.event_type)) {
                                                                        setTeamEventType([...teamEventType, item?.event_type])
                                                                        setValue(`team_event_type`, [...teamEventType, item?.event_type])
                                                                    } else if (!Array.isArray(teamEventType) && teamEventType && teamEventType !== item?.event_type) {
                                                                        setTeamEventType([teamEventType, item?.event_type])
                                                                        setValue(`team_event_type`, [teamEventType, item?.event_type])
                                                                    }
                                                                }}
                                                                isClearable={true}
                                                            />
                                                        )}
                                                        rules={{
                                                            required: allWatchedValues?.event_type === item?.event_type ? 'Age Group is required' : false
                                                        }}
                                                    />
                                                }
                                                id={`${item?.event_type}_${index}`}
                                                value={item?.event_type}
                                                {...register(`team_event_type`)}
                                            />
                                        </Form.Group>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                    {fields.map((item, index) => {
                        return <div className='mb-3 px-3 py-2 border rounded' key={index}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <span className='fs-20 fw-600'>Player - {index + 1}</span>
                                {!state?.edit &&
                                    fields.length > 1 && <IoMdClose role='button' color='red' size={20} onClick={() => remove(index)} />
                                }
                            </div>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>Player Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Player Name"
                                            {...register(`player_details[${index}].player_name`, {
                                                required: "Player name is required",
                                            })}
                                        />
                                        {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_name?.message}</ErrorText>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>Club Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Club Name"
                                            {...register(`player_details[${index}].club_name`)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>Player Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter email"
                                            {...register(`player_details[${index}].player_email`, {
                                                required: "Player Email is required",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                        />
                                        {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_email?.message}</ErrorText>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>Player Contact Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            placeholder="Enter Player Contact Number"
                                            {...register(`player_details[${index}].player_contact`, {
                                                required: "Player Contact Number is required",
                                                minLength: {
                                                    value: 9,
                                                    message: "Contact Number should be 9 digit minimum"
                                                },
                                                maxLength: {
                                                    value: 11,
                                                    message: "Contact Number should be 11 digit maximum"
                                                }
                                            })}
                                        />
                                        {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_contact?.message}</ErrorText>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>Player Gender</Form.Label>
                                        <Form.Select
                                            {...register(`player_details[${index}].player_gender`, {
                                                required: "Player Gender is required"
                                            })}
                                        >
                                            <option value='MALE'>Male</option>
                                            <option value='FEMALE'>Female</option>
                                        </Form.Select>
                                        {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_gender?.message}</ErrorText>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="d-flex flex-column align-items-start my-2">
                                        <Form.Label className='text-fs-14 fw-500'>Date of Birth</Form.Label>
                                        <Controller
                                            control={control}
                                            name={`player_details[${index}].player_dob`}
                                            render={({ field }) => (
                                                <DatePickerWithYearAndMonth
                                                    {...field}
                                                    selected={field.value}
                                                    onChange={(date) => field.onChange(date)}
                                                    maxDate={new Date()}
                                                />
                                            )}
                                            rules={{ required: 'Date of Birth is required' }}
                                        />
                                        {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_dob?.message}</ErrorText>}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>Player IC/Passport Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Player IC/Passport Number"
                                            {...register(`player_details[${index}].player_idcard`, {
                                                required: "Player IC/Passport Number is required",
                                            })}
                                        />
                                        {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.player_idcard?.message}</ErrorText>}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Group className="my-2">
                                        <Form.Label className='fs-14 fw-500'>Country</Form.Label>
                                        <Form.Select
                                            {...register(`player_details[${index}].nationality`, {
                                                required: "Player Country is required",
                                            })}
                                        >
                                            <option value="">Select player country</option>
                                            {countryList?.map((item, index) => {
                                                return <option value={item} key={index}>{item}</option>
                                            })}
                                        </Form.Select>
                                        {errors?.player_details?.length > 0 && <ErrorText>{errors?.player_details[index]?.nationality?.message}</ErrorText>}
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Check
                                className='mb-2'
                                type='checkbox'
                                label={<span role='button' className='fs-14 fw-600 text-secondary'>
                                    Yes, I am a State/District/National Player
                                </span>}
                                id={`proffesional_player${index}`}
                                {...register(`player_details[${index}].proffesional_player`)}
                            />

                            <Form.Check
                                type='checkbox'
                                label={<span role='button' className='fs-14 fw-600 text-secondary'>
                                    I agree that the information shared are true and confirms
                                </span>}
                                id={`declare${index}`}
                                required
                            />
                        </div>
                    })}

                    <div className='d-flex justify-content-end align-items-end mt-4'>
                        <div>
                            <PrimaryButton className='fs-14 fw-500 px-5 py-2' disabled={loading} loading={loading} type="submit">Join Tournament</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </>
        }
    </HelmetProvider>
};

export default EnterTournament;