import axios from "axios"

/**
    * @function allCustomerBannerService
    * @param
    * @description This function is used to get all banner for customer
*/
export const allCustomerBannerService = async() =>{
    try {
        const result = await axios.get(`/customer/banner/list`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allOrganizerBannerService
    * @param
    * @description This function is used to get all banner for organizer
*/
export const allOrganizerBannerService = async() =>{
    try {
        const result = await axios.get(`/organizer/banner/list`)
        return result;
    } catch (error) {
        return error;
    }
}