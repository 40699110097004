import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import MyFavoriteTournament from "../../components/FavoriteTournament/MyFavoriteTournament";

const FavoriteTournament = () => { 
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Favorite Tournament | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-25 fw-600 mt-4'>Favorite</p>
        </div>

        <MyFavoriteTournament />
    </HelmetProvider>
};

export default FavoriteTournament;