import { Modal, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

// Custom Component
import { PrimaryButton, PrimaryOutlineButton } from '../Button';

const PaymentConfirmModal = (props) => {
    const navigate = useNavigate();

    /**
        * @function handleEdit
        * @params
        * @description used to edit player
    */
    const handleEdit = () => {
        props.handleClose()
        navigate('/enter-tournament', {
            state: {
                tournamentId: props?.tournament?.id,
                player_details: props?.tournament?.player_details?.filter(item => !item?.receipt_no && item?.payment_status === "NOTPROCESSED"),
                edit: true
            }
        });
    }

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header className='border-0' closeButton />
        <Modal.Body className='d-flex flex-column align-items-center'>
            <span className='fs-30 fw-600 text-primary mt-3 mb-2'>Important note!</span>
            <span className='fs-18 fw-600 text-dark text-center'>Payment Made are Not Refundable. Please make sure all info are correct.</span>
            <Row className='mb-4 w-100 mt-3 justify-content-center'>
                <Col xs={6} md={4}>
                    <PrimaryOutlineButton className='fs-14 fw-500 text-dark border-secondary' onClick={handleEdit}>Edit</PrimaryOutlineButton>
                </Col>
                <Col xs={6} md={4}>
                    <PrimaryButton className='fs-14 fw-500' onClick={props?.createPayment}>Confirm</PrimaryButton>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
};

export default PaymentConfirmModal;