import axios from "axios"

/**
    * @function currentBalanceService
    * @param
    * @description This function is used to get current balance for organizer
*/
export const currentBalanceService = async() =>{
    try {
        const result = await axios.get(`/organizer/wallet/current-balance`)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function withdrawWalletService
    * @param data
    * @description This function is used to withdraw wallet for organizer
*/
export const withdrawWalletService = async(data) =>{
    try {
        const result = await axios.post(`/organizer/wallet/withdraw`, data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allTransactionHistoryService
    * @param { paginate, page, perPage, keyword }
    * @description This function is used to get all transaction history for organizer
*/
export const allTransactionHistoryService = async({ paginate, page, perPage, keyword }) =>{
    try {
        const result = await axios.get(`/organizer/wallet/transaction-history?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        return result;
    } catch (error) {
        return error;
    }
}