import { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import classNames from "classnames";

// Images
import { logo } from '../../assets/images';

// Redux-Functions
import { setFooterVisiblity } from "../../redux/userSlice";

const Footer = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { ref, inView } = useInView({
        threshold: 0
    });

    useEffect(() => {
        dispatch(setFooterVisiblity(inView))
        // eslint-disable-next-line
    }, [inView])
    
    return <footer ref={ref} className={classNames('border-top border-secondary', {"d-none": props.authenticatedRoute.includes(location.pathname) })}>
        <Container className='py-3'>
            <Row className='text-center text-md-start'>
                <Col lg={2}>
                    <Row className='mb-4 mb-lg-0 mx-0 justify-content-start align-items-center'>
                        <Link to="/" className='d-contents'>
                            <img className='img-fluid' src={logo} alt='logo' />
                        </Link>
                    </Row>
                </Col>
                <Col lg={7}>
                    <Row className='h-100'>
                        <Col xs={5} lg={5} className='d-flex flex-column d-lg-flex flex-lg-row justify-content-end align-items-start align-items-lg-center text-center pe-0'>
                            <Link to='/about-us' className='text-decoration-none mt-2 me-4'>
                                <span className='fw-bold footer-text'>About Us</span>
                            </Link>
                            <Link to='/contact-us' className='text-decoration-none mt-2 me-4'>
                                <span className='fw-bold footer-text'>Contact Us</span>
                            </Link>
                        </Col>
                        <Col xs={7} lg={7} className='d-flex flex-column d-lg-flex flex-lg-row justify-content-start align-items-start align-items-lg-center text-center ps-0'>
                            <Link to='/privacy-policy' className='text-decoration-none mt-2 me-4'>
                                <span className='fw-bold footer-text'>Privacy policy</span>
                            </Link>
                            <Link to='/terms-and-condition' className='text-decoration-none mt-2 me-4'>
                                <span className='fw-bold footer-text'>Terms & Conditions</span>
                            </Link>
                        </Col>
                    </Row>
                </Col>
                <Col lg={3}>
                    <div className='d-flex flex-column'>
                        <span className='fw-400 text-secondary text-start text-lg-center mt-2'>© 2023 AFA Community</span>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>;
};

export default Footer;