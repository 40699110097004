import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';

// Custom Component
import MySubOrganizer from '../../components/SubOrganizer/MySubOrganizer';

const SubOrganizer = () => {
    return <HelmetProvider>
        <Helmet>
            <title>Sub Organizer | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container className='my-4'>
            <Row>
                <Col xs={12} className='position-relative'>
                    <MySubOrganizer />
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default SubOrganizer;