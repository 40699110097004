import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { BiErrorCircle } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

const MySwal = withReactContent(Swal)

const ErrorAlert = (message) => {
    MySwal.fire({
        html: (
            <div className="d-flex justify-content-between align-items-center border border-danger rounded p-3">
                <div className="d-flex align-items-center">
                    <BiErrorCircle className='text-danger me-2' size={20} />
                    <span className='fs-14 fw-600 text-danger'>{message || 'Something went wrong'}</span>
                </div>
                <IoMdClose role='button' className='text-danger' size={20} onClick={() => {
                    MySwal.close();
                }} />
            </div>
        ),
        position: 'top',
        timer: 3000
    });
};

export default ErrorAlert;