import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { FiEdit3 } from "react-icons/fi";
import classNames from 'classnames';

// Custom Component
import { PrimaryOutlineButton } from "../../components/Button";
import { BankAccountCard } from "../../components/Card";
import AddBankAccountModal from "../../components/Modal/AddBankAccountModal";
import EditBankAccountModal from "../../components/Modal/EditBankAccountModal";
import NoBank from "../../components/Common/NoBank";
import ErrorAlert from '../../components/Alert/ErrorAlert';

// API Service
import { allBankService, allBankAccountService } from '../../service/bank.service';

// Redux-Functions
import { setBankAccountList, selectBankAccountList } from "../../redux/bankAccountSlice";
import { selectUser } from "../../redux/userSlice";

const BankAccount = () => {
    const dispatch = useDispatch();
    const bankAccountList = useSelector(selectBankAccountList)
    const user = useSelector(selectUser)

    const [bankList, setBankList] = useState([])
    const [addBankAccountModal, setAddBankAccountModal] = useState(false)
    const [editBankAccountModal, setEditBankAccountModal] = useState(false)

    /**
        * @function fetchBankList
        * @params
        * @description fetch the list of bank
    */
    const fetchBankList = async () => {
        try {
            const result = await allBankService();
            if (result?.data?.status) {
                setBankList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchBankAccountList
        * @params
        * @description fetch the list of bank account
    */
    const fetchBankAccountList = async () => {
        try {
            const result = await allBankAccountService();
            if (result?.data?.status) {
                dispatch(setBankAccountList(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchBankList();
        fetchBankAccountList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
        * @function handleAddBankAccountModal
        * @params
        * @description set the visibility of add bank account modal
    */
    const handleAddBankAccountModal = () => {
        setAddBankAccountModal(!addBankAccountModal)
    }

    /**
        * @function handleEditBankAccountModal
        * @params
        * @description set the visibility of edit bank account modal
    */
    const handleEditBankAccountModal = () => {
        setEditBankAccountModal(!editBankAccountModal)
    }

    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Bank Account | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <div className='d-flex justify-content-between align-items-center'>
            <p className='fs-25 fw-600 mt-4'>Bank Account</p>
            {bankList?.length > 0 &&
                <>
                    {bankAccountList?.length > 0 ?
                        <div>
                            <PrimaryOutlineButton className='fs-14 fw-500' onClick={handleEditBankAccountModal} disabled={user?.type === 'organizer' && user?.parent_id}>Change Bank Account</PrimaryOutlineButton>
                        </div>
                        :
                        <div>
                            <PrimaryOutlineButton className='fs-14 fw-500' onClick={handleAddBankAccountModal} disabled={user?.type === 'organizer' && user?.parent_id}>Add Bank Account</PrimaryOutlineButton>
                        </div>
                    }
                </>
            }
        </div>

        {bankAccountList?.length > 0 ?
            <div className='d-flex flex-column align-items-center border bg-gray rounded p-3'>
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <span className='fw-600'>Bank Detail</span>
                    <div role='button' className={classNames('d-flex align-items-center text-secondary', {
                        "opacity-25 pe-none": user?.parent_id
                    })} onClick={handleEditBankAccountModal}>
                        <FiEdit3 className='me-2' size={20} />
                        <span className='fw-600'>Edit Account</span>
                    </div>
                </div>
                {bankAccountList?.map((item, index) => {
                    return <BankAccountCard item={item} key={index} />
                })}
            </div>
            :
            <NoBank handleAddBankAccountModal={handleAddBankAccountModal} />
        }

        {/* Add Bank Modal */}
        <AddBankAccountModal show={addBankAccountModal} handleClose={handleAddBankAccountModal} size='lg' />

        {/* Edit Bank Modal */}
        <EditBankAccountModal show={editBankAccountModal} handleClose={handleEditBankAccountModal} size='lg' />
    </HelmetProvider>
};

export default BankAccount;