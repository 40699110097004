import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import MyTournamentHistory from "../../components/TournamentHistory/MyTournamentHistory";

const TournamentHistory = () => { 
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>Tournament History | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <div className='px-3 px-lg-0'>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='fs-25 fw-600 mt-4'>Entered Tournaments</p>
            </div>
            <MyTournamentHistory />
        </div>
    </HelmetProvider>
};

export default TournamentHistory;