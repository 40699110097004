import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    searchTerm: '',
    pdfLoading: '',
}

export const tournamentSlice = createSlice({
    name: "tournament",
    initialState,
    reducers: {
        setSearchTerm: (state, action) => {
            state.searchTerm = action.payload;
        },
        setPdfLoading: (state, action) => {
            state.pdfLoading = action.payload;
        }
    }
})

export const {
    setSearchTerm,
    setPdfLoading
} = tournamentSlice.actions

export const selectSearchTerm = (state) => state.tournament.searchTerm;
export const selectPdfLoading = (state) => state.tournament.pdfLoading;

export default tournamentSlice.reducer