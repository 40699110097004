import { Modal } from 'react-bootstrap';
import { IoCloseOutline } from "react-icons/io5";

// Images
import { Error } from '../../assets/images/svg';

const PaymentErrorModal = (props) => {
    return <Modal show={props.show} onHide={props.handleClose} backdrop="static" size='lg' centered>
        <Modal.Header className='d-flex flex-column justify-content-center align-items-center border-0 bg-danger text-light px-0 py-5 position-relative'>
            <IoCloseOutline role='button' className='text-light position-absolute top-0 end-0 mt-2 me-2' size={20} onClick={props.handleClose} />
            <Error />
            <span className='fs-30 fw-600 mt-3 mb-2'>Oops!</span>
            <span className='text-center'>Something went Wrong, Please try again later</span>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column align-items-center'>
            <span role='button' className='fs-16 fw-bold text-secondary mb-4' onClick={props.handleClose}>Go back</span>
        </Modal.Body>
    </Modal>
};

export default PaymentErrorModal;