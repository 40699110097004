import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';

// Custom Component
import MyReceipt from '../../components/MyReceipt/MyReceipt';

const Receipt = () => {
    return <HelmetProvider>
        <Helmet>
            <title>Receipts | AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <Container fluid className='my-4'>
            <Row>
                <Col xs={12} className='position-relative'>
                    <MyReceipt />
                </Col>
            </Row>
        </Container>
    </HelmetProvider>
};

export default Receipt;