import { useState, useEffect } from 'react';
import { Modal, Form, Spinner } from 'react-bootstrap';
import Select, { components } from 'react-select';
import LoadingOverlay from 'react-loading-overlay';
import { Link } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { useSelector } from 'react-redux';

// Custom Component
import ErrorText from '../TextField/ErrorText'
import { PrimaryButton, PrimaryOutlineButton } from '../Button';
import SuccessAlert from '../Alert/SuccessAlert';
import ErrorAlert from '../Alert/ErrorAlert';

// API Service
import { allTournamentService } from '../../service/tournament.service';

// Redux-Functions
import { selectUser } from "../../redux/userSlice";

const AddTShirtModal = (props) => {
    const user = useSelector(selectUser);

    const [tournamentList, setTournamentList] = useState([])
    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined

    const { register, control, reset, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            tshirt_details: [
                {
                    size: "",
                    quantity: ""
                }
            ]
        },
        mode: "onBlur"
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: "tshirt_details"
    });

    /**
        * @function fetchTournamentList
        * @params
        * @description fetch the list of tournament
    */
    const fetchTournamentList = async () => {
        try {
            const result = await allTournamentService({
                paginate: 0,
                page: '',
                perPage: '',
                keyword: ''
            });
            if (result?.data?.status) {
                setTournamentList(result?.data?.data?.tournament_lists)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
        } catch (error) {
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        if (user?.type === 'organizer') {
            fetchTournamentList()
        }
        // eslint-disable-next-line
    }, [])

    /**
        * @function onSubmit
        * @params formdata
        * @description used to add tshirt 
    */
    const onSubmit = async (formdata) => {
        try {
            const data = new FormData()
            console.log(data, formdata)
            setLoading(true)
            let result;
            if (result?.data?.status) {
                SuccessAlert(result?.data?.message)
                handleClose()
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
        * @function handleClose
        * @params
        * @description used to close add tshirt modal
    */
    const handleClose = async () => {
        reset()
        props.handleClose()
    }

    const reactSelectCustomStyle = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            border: state.isFocused && "1px solid #FA5000",
            boxShadow: state.isFocused && "0px 0px 2px #FA5000",
            "&:hover": {
                border: "1px solid #FA5000",
                boxShadow: "0px 0px 2px #FA5000"
            }
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? '#FA5000' : 'white',
                "&:hover": {
                    backgroundColor: "#FA5000",
                    color: 'white'
                }
            }
        }
    }

    /**
        * @function SelectMenuButton
        * @params props
        * @description used for add button in select dropdown
    */
    const SelectMenuButton = (props) => {
        return <components.MenuList {...props}>
            {props.children}
            <div className='py-3 px-1'>
                <Link to='/tournament' className='text-decoration-none'>
                    <div className='d-inline-block'>
                        <PrimaryOutlineButton className='rounded-pill px-2 py-1'>+ Tournament</PrimaryOutlineButton>
                    </div>
                </Link>
            </div>
        </components.MenuList>
    }

    return <Modal show={props.show} onHide={handleClose} size={props.size} centered backdrop="static" keyboard={false}>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-22 fw-bold border-0' closeButton >
                Add T-Shirt
            </Modal.Header>
            <Modal.Body className='d-flex flex-column align-items-center'>
                <Form className='w-100' onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>T-shirt name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name here"
                            {...register("name", {
                                required: "T-shirt name is required"
                            })}
                        />
                        {errors.name && <ErrorText>{errors.name.message}</ErrorText>}
                    </Form.Group>

                    <Form.Group className="my-4">
                        <Form.Label className='fs-14 fw-500'>Tournaments</Form.Label>
                        <Controller
                            name={`tournament_id`}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    styles={reactSelectCustomStyle}
                                    placeholder='Select tournaments here'
                                    components={{ MenuList: SelectMenuButton }}
                                    options={tournamentList?.map((item) => {
                                        return {
                                            value: item?.id,
                                            label: item?.name
                                        }
                                    })}
                                    formatOptionLabel={(data) => {
                                        return (
                                            <div className='d-flex align-items-center'>
                                                <img className='tournament-banner-small rounded-circle me-2' src={tournamentList?.find(item => item?.id === data?.value)?.banner_image} alt={data.label} />
                                                <span>{data.label}</span>
                                            </div>
                                        );
                                    }}
                                    isMulti={true}
                                    isSearchable={true}
                                    {...field}
                                />
                            )}
                            rules={{
                                required: "Tournament is required"
                            }}
                        />
                        {errors.tournament_id && <ErrorText>{errors.tournament_id.message}</ErrorText>}
                    </Form.Group>

                    <div className='mt-3 p-3 border bg-gray rounded'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <span className='fw-600 text-dark'>T-Shirt size and Quantity</span>
                        </div>
                        {fields.map((item, index) => {
                            return (
                                <div className='d-flex flex-wrap align-items-start mt-4 position-relative' key={item.id}>
                                    <div className='d-flex flex-column flex-sm-row flex-sm-wrap align-items-start flex-grow-1'>
                                        <div className='w-xs-100 flex-grow-1 w-xs-100 me-2'>
                                            <Form.Group>
                                                <Form.Label className='fs-14 fw-500'>T-shirt size</Form.Label>
                                                <Form.Select
                                                    {...register(`tshirt_details[${index}].size`, {
                                                        required: "Size is required"
                                                    })}>
                                                    <option value="S">S</option>
                                                    <option value="M">M</option>
                                                    <option value="L">L</option>
                                                    <option value="XL">XL</option>
                                                    <option value="XXL">XXL</option>
                                                </Form.Select>
                                            </Form.Group>
                                            {errors?.tshirt_details?.length > 0 && errors?.tshirt_details[index]?.size && <ErrorText>{errors?.tshirt_details[index]?.size?.message}</ErrorText>}
                                        </div>
                                    </div>
                                    <div className='w-xs-100 flex-grow-1 mt-2 mt-sm-0 mx-sm-2'>
                                        <Form.Group>
                                            <Form.Label className='fs-14 fw-500'>Quantity</Form.Label>
                                            <Form.Control
                                                type="number"
                                                {...register(`tshirt_details[${index}].quantity`, {
                                                    required: "Quantity is required"
                                                })}
                                            />
                                            {errors?.tshirt_details?.length > 0 && errors?.tshirt_details[index]?.quantity && <ErrorText>{errors?.tshirt_details[index]?.quantity?.message}</ErrorText>}
                                        </Form.Group>
                                    </div>

                                    {fields.length > 1 && <IoMdClose role='button' className='position-absolute top-0 end-0' color='red' size={20} onClick={() => remove(index)} />}
                                </div>
                            );
                        })}
                        <div role='button' className='d-inline-flex align-items-center mt-4' onClick={() =>
                            append({
                                size: "",
                                quantity: ""
                            })
                        }>
                            <BsFillPlusCircleFill className='text-primary me-2' size={25} />
                            <span className='text-primary fw-500'>Add more size</span>
                        </div>
                    </div>

                    <div className='d-flex justify-content-end align-items-end mt-4'>
                        <div>
                            <PrimaryButton className='fs-14 fw-500 px-5' disabled={loading} loading={loading} type="submit">Add</PrimaryButton>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default AddTShirtModal;