import { useState, useEffect } from 'react';
import { Modal, Form, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// Custom Component
import ErrorText from '../TextField/ErrorText'
import ErrorAlert from '../Alert/ErrorAlert'
import { PrimaryButton } from '../Button';

// API Service
import { allBankService, allBankAccountService, updateBankAccountService } from '../../service/bank.service';

// Redux-Functions
import { setBankAccountList, selectBankAccountList } from "../../redux/bankAccountSlice"

const EditBankAccountModal = (props) => {
    const dispatch = useDispatch();
    const bankAccountList = useSelector(selectBankAccountList)

    const [bankList, setBankList] = useState([])
    const [loading, setLoading] = useState(false);
    LoadingOverlay.propTypes = undefined

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    /**
        * @function fetchBankList
        * @params
        * @description fetch the list of bank
    */
    const fetchBankList = async () => {
        try {
            setLoading(true)
            const result = await allBankService();
            if (result?.data?.status) {
                setBankList(result?.data?.data)
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    /**
        * @function fetchBankAccountList
        * @params
        * @description fetch the list of bank account
    */
    const fetchBankAccountList = async () => {
        try {
            setLoading(true)
            const result = await allBankAccountService();
            if (result?.data?.status) {
                dispatch(setBankAccountList(result?.data?.data))
            } else {
                ErrorAlert(result?.response?.data?.message)
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    useEffect(() => {
        fetchBankList();
    }, [])

    // for setting default values
    useEffect(() => {
        if (bankAccountList?.length > 0) {
            let defaultValues = {};
            defaultValues.bank_id = bankAccountList[0]?.bank_id
            defaultValues.holder_name = bankAccountList[0]?.holder_name
            defaultValues.account_number = bankAccountList[0]?.account_number
            reset({ ...defaultValues })
        } else {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccountList]);

    /**
        * @function onSubmit
        * @params formdata
        * @description used to edit bank account for organizer
    */
    const onSubmit = async (formdata) => {
        const data = new FormData()
        data.append('_method', 'PUT')
        data.append('bank_id', formdata?.bank_id)
        data.append('holder_name', formdata?.holder_name)
        data.append('account_number', formdata?.account_number)
        try {
            if (bankAccountList?.length > 0) {
                setLoading(true)
                const result = await updateBankAccountService({
                    id: bankAccountList[0]?.id,
                    data
                });
                if (result?.data?.status) {
                    reset()
                    fetchBankAccountList()
                    props.handleClose()
                } else {
                    ErrorAlert(result?.response?.data?.message)
                }
            } else {
                ErrorAlert("You don't have any bank account for update")
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorAlert(error)
        }
    }

    return <Modal show={props.show} onHide={props.handleClose} centered>
        <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" />}
        >
            <Modal.Header className='fs-24 fw-600 border-0' closeButton>
                Edit Bank Account
            </Modal.Header>
            <Modal.Body className='d-flex flex-column pt-0 align-items-center'>
                <div className='w-100'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        {bankList?.length > 0 &&
                            <Form.Group>
                                <Form.Label className='fs-14 fw-500'>Bank Name</Form.Label>
                                <Form.Select
                                    {...register("bank_id", {
                                        required: "Bank Name is required"
                                    })}
                                >
                                    {bankList.map((item, index) => {
                                        return <option value={item?.id} key={index}>{item?.name}</option>
                                    })}
                                </Form.Select>
                                {errors.bank_id && <ErrorText>{errors.bank_id.message}</ErrorText>}
                            </Form.Group>
                        }

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Bank Account number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter bank account number"
                                {...register("account_number", {
                                    required: "Bank Account number is required"
                                })}
                            />
                            {errors.account_number && <ErrorText>{errors.account_number.message}</ErrorText>}
                        </Form.Group>

                        <Form.Group className="my-4">
                            <Form.Label className='fs-14 fw-500'>Bank account holder name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter bank account holder name"
                                {...register("holder_name", {
                                    required: "Bank Account number is required"
                                })}
                            />
                            {errors.holder_name && <ErrorText>{errors.holder_name.message}</ErrorText>}
                        </Form.Group>

                        <div className='d-flex justify-content-end align-items-end mt-4'>
                            <div>
                                <PrimaryButton className='fs-14 fw-500 px-5' disabled={loading} loading={loading} type="submit">Edit Account</PrimaryButton>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </LoadingOverlay>
    </Modal>
};

export default EditBankAccountModal;