import { Helmet, HelmetProvider } from 'react-helmet-async';

// Custom Component
import BannerSection from "../../components/Homepage/BannerSection";
import TabSection from "../../components/Homepage/TabSection";

const Homepage = () => {
    return <HelmetProvider>
        <Helmet prioritizeSeoTags>
            <title>AFA Tournament - Your Gateway to Sporting Excellence!</title>
        </Helmet>
        <BannerSection />
        <div className='px-3 px-lg-0'>
            <p className='fs-25 fw-600 mt-2 mt-lg-4'>Tournaments</p>
            <TabSection />
        </div>
    </HelmetProvider>
};

export default Homepage;