import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    bankAccountList: []
}

export const bankAccountSlice = createSlice({
    name: "bankAccountSlice",
    initialState,
    reducers: {
        setBankAccountList: (state, action) => {
            state.bankAccountList = action.payload
        }
    }
})

export const { setBankAccountList } = bankAccountSlice.actions

export const selectBankAccountList = (state) => state.bankAccount.bankAccountList 

export default bankAccountSlice.reducer