const Women = (props) => {
    return <svg width="19" height="19" className={props.className} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_136_11762)">
            <path d="M16.3994 11C16.3994 13.0727 16.934 14.8113 17.4005 16.0621C17.7015 16.8692 17.2979 17.8129 16.4825 17.9521C15.0191 18.2017 12.5752 18.5 9.25002 18.5C5.92477 18.5 3.48086 18.2017 2.01747 17.9521C1.20207 17.8129 0.798509 16.8692 1.0995 16.0621C1.56598 14.8113 2.10064 13.0727 2.10064 11" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7 11.375V11.75" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5 11.375V11.75" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.5 14C10.5156 15.5 7.98438 15.5 7 14" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.69159 5C2.90317 6.3752 1.75 8.54028 1.75 10.9756C1.75 15.1312 5.10788 18.5 9.25 18.5C13.3921 18.5 16.75 15.1312 16.75 10.9756C16.75 8.5436 15.5999 6.38104 13.8156 5.00553" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.16136 2.91479C7.31933 2.99369 7.51134 2.94662 7.6242 2.81505C8.00415 2.37201 8.61791 1.72949 8.97098 1.36451C9.11866 1.21183 9.37068 1.21183 9.51836 1.3645C9.87144 1.72949 10.4852 2.37201 10.8652 2.81505C10.978 2.94662 11.17 2.99369 11.328 2.91479C11.9559 2.60121 12.6653 2.13635 13.1566 1.79653C13.4096 1.62156 13.7643 1.78952 13.7496 2.08694C13.7116 2.84941 13.5973 4.07204 13.2559 5.06378C13.2233 5.15848 13.1486 5.23336 13.0497 5.26581C12.6329 5.40244 11.3645 5.75 9.24467 5.75C7.16053 5.75 5.89962 5.41407 5.46173 5.27288C5.35012 5.23688 5.26995 5.14731 5.24243 5.03822C4.92287 3.77096 4.79886 2.72998 4.75099 2.08273C4.72909 1.78654 5.07658 1.61931 5.32883 1.79384C5.82031 2.1339 6.53181 2.60038 7.16136 2.91479Z" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.75 11C5.17996 10.8513 8.07731 8.71037 9.25 5.75" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.25 5.75C10.4228 8.71019 13.3201 10.851 16.75 11" stroke={props.color || "#475467"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_136_11762">
                <rect width="18" height="18" fill="white" transform="translate(0.25 0.5)" />
            </clipPath>
        </defs>
    </svg>;
}

export default Women;