import axios from "axios"

/**
    * @function addSubOrganizerService 
    * @param data
    * @description This function is used to add sub organizer
*/
export const addSubOrganizerService = async (data) => {
    try {
        const result = await axios.post('/organizer/sub-organizer/store', data)
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function allSubOrganizerService
    * @param { paginate, page, perPage, keyword, status } 
    * @description This function is used to get all sub organizer for organizer
*/
export const allSubOrganizerService = async ({ paginate, page, perPage, keyword, status }) => {
    try {
        let result;
        if (status) {
            result = await axios.get(`/organizer/sub-organizer/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}&status=${status}`)
        } else {
            result = await axios.get(`/organizer/sub-organizer/list?paginate=${paginate}&page=${page}&perPage=${perPage}&keyword=${keyword}`)
        }
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function updateSubOrganizerService
    * @param id, data
    * @description This function is used to update sub organizer for organizer
*/
export const updateSubOrganizerService = async (id, data) => {
    try {
        const result = await axios.post(`/organizer/sub-organizer/update/${id}`, data)
        return result;
    } catch (error) {
        return error;
    }
}