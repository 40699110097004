import { Link } from "react-router-dom";
import { BiHomeAlt } from "react-icons/bi";
import { IoIosArrowForward } from "react-icons/io";

const Breadcrumb = (props) => {
    return <div className='d-flex align-items-center mt-4'>
        <Link to='/'>
            <BiHomeAlt className='text-secondary me-2' size={20} />
        </Link>
        <IoIosArrowForward className='text-secondary me-2' size={18} />
        <Link to='/' className="text-decoration-none">
            <span className='fw-400 text-secondary me-2'>Tournaments</span>
        </Link>
        <IoIosArrowForward className='text-secondary me-2' size={18} />
        <span className='fw-400 text-secondary me-2'>...</span>
        <IoIosArrowForward className='text-secondary me-2' size={18} />
        <span className='fw-600 text-primary'>{props?.text}</span>
    </div>
};

export default Breadcrumb;